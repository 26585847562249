import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'

const useAddResidentCreditOrDebit = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage, showSystemMessage } = useToastNotification()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleAddCreditOrDebit = async (
    residentId: number,
    amount: number,
    type: string
  ) => {
    setIsSubmitting(true)

    await axiosPrivate
      .patch(`/api/residents/${residentId}/credit-debit`, { type, amount })
      .then(() => {
        window.location.reload()
        showSystemMessage('success', `${type} adicionado com sucesso.`)
      })
      .catch((error) => {
        showApiErrorMessage(
          error,
          `Erro ao adicionar ${type}. Por favor, tente novamente.`
        )
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return {
    handleAddCreditOrDebit,
    isSubmitting,
  }
}

export default useAddResidentCreditOrDebit
