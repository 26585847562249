import { useContext } from 'react'
import { AuthContext } from '../../context/AuthProvider'
import { axiosBase } from '../axios'
import { jwtDecoder } from '../../utils/helper/helper'

const useRefreshToken = () => {
  const { auth, setAuth } = useContext(AuthContext)

  const refresh = async () => {
    return await axiosBase
      .post(
        '/api/tokens/refresh',
        JSON.stringify({ refreshToken: auth?.refreshToken })
      )
      .then((response) => {
        const decodedToken = jwtDecoder(response.data)
        if (typeof decodedToken === 'undefined') {
          return null
        }

        setAuth(decodedToken)

        return response.data.token
      })
      .catch((err) => {
        return null
      })
  }
  return refresh
}

export default useRefreshToken
