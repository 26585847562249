import React from 'react'

const MaintenanceAccountingList = () => {
  return (
    <div>
      <h1>Manutenção</h1>
      <br />
      <h2>Apenas será implementado na segunda fase do projecto</h2>
    </div>
  )
}

export default MaintenanceAccountingList
