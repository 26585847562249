import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import React, { ChangeEvent, useState } from 'react'
import useAddIdea from '../../../hooks/ideas/useAddIdea'

type IdeaData = {
  title: string
  description: string
}

type AddNewIdeaProps = {
  handleFetchMaintenances: () => void
}

const AddNewIdea = ({ handleFetchMaintenances }: AddNewIdeaProps) => {
  const { handleAddIdea, isSubmitting } = useAddIdea()
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [ideaData, setIdeaData] = useState<IdeaData>({
    title: '',
    description: '',
  })

  const handleClosePopup = () => {
    setOpenPopup(false)
    setIdeaData({
      title: '',
      description: '',
    })
  }

  const handleSuccessAddIdea = () => {
    handleFetchMaintenances()
    handleClosePopup()
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setIdeaData({
      ...ideaData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  return (
    <React.Fragment>
      <Button
        color='warning'
        variant='contained'
        onClick={() => setOpenPopup(true)}
      >
        Adicionar Ideais / Sugestões
      </Button>

      <Dialog open={openPopup} onClose={handleClosePopup} fullWidth>
        <DialogTitle sx={{ fontWeight: 700 }}>
          Adicionar Ideia / Sugestão
        </DialogTitle>

        <DialogContent>
          <Box>
            <TextField
              name='title'
              label='Título'
              defaultValue={ideaData.title}
              size='small'
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 1, mt: 1 }}
              required
            />
          </Box>
          <Box>
            <TextField
              name='description'
              label='Descrição'
              defaultValue={ideaData.description}
              size='small'
              onChange={handleInputChange}
              fullWidth
              required
              multiline
              rows={4}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ mr: 3, mb: 2 }}>
          <Button
            variant='outlined'
            size='small'
            color='error'
            disabled={isSubmitting}
            onClick={handleClosePopup}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            size='small'
            color='warning'
            disabled={isSubmitting}
            onClick={() =>
              handleAddIdea({
                title: ideaData.title,
                description: ideaData.description,
                successFn: handleSuccessAddIdea,
              })
            }
          >
            Criar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default AddNewIdea
