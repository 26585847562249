import React, { useEffect, useState } from 'react'
import { getPaymentQueueForSingleResidentBlockApiData } from '../../../../utils/rest'
import { PaymentQueue } from '../../../../interfaces/paymentqueue'
import { useTranslation } from 'react-i18next'
import {
  TBlockPaymentList,
  TBlockPaymentsList,
  TSetDataFilter,
} from '../types/types'
import LoadingCircular from '../../../defaults/Loading'
import PageWithoutData from '../../../../pages/admin/PageWithoutData'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import {
  API_URLS,
  convertDateToFullFormat,
  numberFormatting,
} from '../../../../utils/helper/helper'
import ActiveIcon from '../../../../pages/admin/layout/ActiveIcon'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import BlockPaymentsFilters from './BlockPaymentsFilters'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import FileList from '../../files/FileList'
import { useNavigate } from 'react-router-dom'

export default function BlockPaymentsList({
  residentBlock,
}: TBlockPaymentsList) {
  const { t } = useTranslation(['home'])
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const [loading, setLoading] = useState(true)
  const [errorFetching, setErrorFetching] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [residentPayments, setResidentPayments] = useState<PaymentQueue[]>([])
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false)
  const [dataFilters, setDataFilters] = useState<TSetDataFilter>({
    type: 'listFilter',
    paid: true,
    notPaid: true,
    fromDate: null,
    toDate: null,
    page: 0,
  })

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setDataFilters({
      ...dataFilters,
      page: newPage,
    })
  }

  useEffect(() => {
    const fetchResidentPayments = async () => {
      setLoading(true)

      const apiResponse: TBlockPaymentList =
        await getPaymentQueueForSingleResidentBlockApiData(
          axiosPrivate,
          residentBlock.id,
          dataFilters
        )

      if (!apiResponse.success) {
        setErrorFetching(true)
      } else {
        setResidentPayments(apiResponse.data)
        setTotalItems(apiResponse.totalItems ?? 0)
      }

      setLoading(false)
    }

    fetchResidentPayments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilters])

  return (
    <React.Fragment>
      {loading ? (
        <LoadingCircular />
      ) : errorFetching ? (
        <PageWithoutData />
      ) : (
        <React.Fragment>
          <Box display={'flex'}>
            <Typography variant='h2' className='content-title'>
              {t('admin.management.blocks.edit.tenantPayments.title')}{' '}
              {residentBlock.resident.name}
            </Typography>
            <Box ml={'auto'}>
              <Button
                color='warning'
                variant='contained'
                sx={{ mr: 1 }}
                onClick={() => navigate(`${API_URLS.accounting}/new`)}
              >
                {t('admin.management.blocks.edit.newPayment')}
              </Button>
              <Button
                variant='outlined'
                onClick={() => setOpenFiltersDialog(true)}
              >
                <FilterListOutlinedIcon />
                &nbsp;
                {t('default.filters')}
              </Button>
            </Box>
          </Box>
          <BlockPaymentsFilters
            open={openFiltersDialog}
            setOpen={setOpenFiltersDialog}
            dataFilters={dataFilters}
            setDataFilters={setDataFilters}
          />
          <TableContainer component={Paper}>
            <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t(
                      'admin.management.blocks.edit.tenantPayments.description'
                    )}
                  </TableCell>
                  <TableCell>{t('default.createdAt')}</TableCell>
                  <TableCell>{t('default.paid')}?</TableCell>
                  <TableCell>{t('default.amount')}</TableCell>
                  <TableCell>{t('default.lastPaymentAt')}</TableCell>
                  <TableCell>
                    {t('admin.management.blocks.edit.tenantPayments.files')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {residentPayments.map((residentPayment: PaymentQueue) => {
                  return (
                    <TableRow key={residentPayment.id}>
                      <TableCell component='th' scope='row'>
                        {residentPayment.description}
                      </TableCell>
                      <TableCell>
                        {convertDateToFullFormat(residentPayment.createdAt)}
                      </TableCell>
                      <TableCell
                        component='th'
                        scope='row'
                        sx={{ textAlign: 'center' }}
                      >
                        <ActiveIcon isActive={residentPayment.isFullyPaid} />
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <Box>
                          <small>
                            <b>Total:</b>{' '}
                          </small>
                          {numberFormatting(residentPayment.amount)}
                        </Box>
                        <Box>
                          <small>
                            <b style={{ color: 'green' }}>
                              {t('default.paid')}:
                            </b>{' '}
                          </small>
                          {numberFormatting(residentPayment.amountAlreadyPaid)}
                        </Box>
                        {!residentPayment.isFullyPaid && (
                          <Box>
                            <small>
                              <b style={{ color: 'red' }}>
                                {t('default.toPay')}:
                              </b>{' '}
                            </small>
                            {numberFormatting(
                              residentPayment.amount -
                                residentPayment.amountAlreadyPaid
                            )}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>
                        {convertDateToFullFormat(residentPayment.lastPaymentAt)}
                      </TableCell>

                      <TableCell>
                        <FileList
                          files={residentPayment.files}
                          paymentId={residentPayment.id}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ width: '100%' }}>
                  <TablePagination
                    count={totalItems}
                    rowsPerPage={10}
                    page={dataFilters.page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage=''
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
