import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import { Box, TableFooter, Typography } from '@mui/material'
import { getUsersApi } from '../../../utils/rest'
import { Link, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../../api/hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import ActiveIcon from '../layout/ActiveIcon'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'

const UsersList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['home'])
  const axiosPrivate = useAxiosPrivate()
  const [page, setPage] = useState(0)
  const [data, setData] = useState<any[]>([])

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  useEffect(() => {
    const getUsersList = async () => {
      const usersResponse = await getUsersApi(axiosPrivate)
      if (!usersResponse.success) {
        navigate('/')
      }

      setData(usersResponse.data)
    }

    getUsersList()
  }, [axiosPrivate, navigate])

  return (
    <React.Fragment>
      <Typography
        variant='h2'
        sx={{
          fontWeight: 700,
          fontSize: '1.5rem',
          lineHeight: '1.375',
          mb: 2,
        }}
      >
        Users list
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>{t('default.name')}</TableCell>
              <TableCell>Info</TableCell>
              <TableCell>{t('default.balance')}</TableCell>
              <TableCell>{t('default.isActive')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    onClick={() => navigate('/admin/users/' + row.id)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell component='th' scope='row'>
                      <Box>{row.resident && row.resident.name}</Box>
                      <Box sx={{ color: 'grey', fontSize: '12px' }}>
                        {row.email}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {row.resident ? (
                        <React.Fragment>
                          <Box>{row.resident.phone}</Box>
                          <Box sx={{ color: 'grey', fontSize: '12px' }}>
                            <b>NIF:</b> {row.resident.nif}
                          </Box>
                        </React.Fragment>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      {row.resident && row.resident.residentBlock ? (
                        <React.Fragment>
                          {/* //TODO v2: This needs to loop through the residentBlock and sum values up - to be done when users are created */}
                          {/* <Box>
                            <b style={{ color: 'green' }}>
                              {t('default.credit')}:
                            </b>{' '}
                            €{row.resident.residentBlock.balance}
                          </Box>
                          <Box>
                            <b style={{ color: 'red' }}>
                              {t('default.toPay')}:
                            </b>{' '}
                            €{row.resident.residentBlock.rentTotalPaid}
                          </Box> */}
                        </React.Fragment>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'left' }}>
                      <ActiveIcon isActive={row.isActive} />
                    </TableCell>
                    <TableCell>
                      <Link
                        to={'/admin'}
                        style={{
                          color: '#d97706',
                          fontWeight: 700,
                          display: 'inline-flex',
                          alignItems: 'center',
                          textDecoration: 'none',
                        }}
                      >
                        <EditNoteOutlinedIcon />
                        &nbsp;{t('default.edit')}
                      </Link>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ width: '100%' }}>
              <TablePagination
                count={data.length}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                labelRowsPerPage=''
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default UsersList
