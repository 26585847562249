import React, { useState } from 'react'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import AllLatePayments from '../../../components/admin/accounting/NewPayments/AllLatePayments'
import QuotesByReceivedAmount from '../../../components/admin/accounting/NewPayments/QuotesByReceivedAmount'

const paymentTypes = [
  {
    name: 'Pagamentos em atraso',
    value: 0,
  },
  {
    name: 'Pagamento de quotas automático',
    value: 1,
  },
]

const NewAccounting = () => {
  const { t } = useTranslation(['home'])
  const [openSelectDropdown, setOpenSelectDropdown] = useState<boolean>(false)
  const [paymentType, setPaymentType] = useState<string>('')

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target

    setPaymentType(value)
  }

  return (
    <React.Fragment>
      <Typography variant='h2' className='content-title'>
        {t('admin.management.accounting.new.title')}
      </Typography>
      <Grid container mb={2} mt={4}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id='demo-controlled-open-select-label' size='small'>
              {t('admin.management.accounting.new.paymentType')}
            </InputLabel>
            <Select
              labelId='demo-controlled-open-select-label'
              open={openSelectDropdown}
              onClose={() => setOpenSelectDropdown(false)}
              onOpen={() => setOpenSelectDropdown(true)}
              label={t('default.tenant')}
              onChange={handleChange}
              value={paymentType}
              size='small'
              fullWidth
            >
              <MenuItem disabled value='placeholder'>
                <em>
                  {t('admin.management.accounting.new.selectPaymentOption')}
                </em>
              </MenuItem>
              {paymentTypes.map((payment, idx) => (
                <MenuItem key={idx} value={payment.value}>
                  {payment.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {paymentType !== '' && Number(paymentType) === 0 && <AllLatePayments />}
      {paymentType !== '' && Number(paymentType) === 1 && (
        <QuotesByReceivedAmount />
      )}
    </React.Fragment>
  )
}

export default NewAccounting
