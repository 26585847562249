import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'
import { Ideas } from '../../interfaces/ideas'
import { useNavigate } from 'react-router-dom'

const useFetchSingleIdea = (ideaId: string | number | undefined) => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { showApiErrorMessage } = useToastNotification()
  const [ideaData, setIdeaData] = useState<Ideas>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (ideaId === undefined) {
      navigate(-1)
      return
    }

    const handleFetchSingleIdea = async () => {
      setIsLoading(true)

      await axiosPrivate
        .get(`/api/ideas/${ideaId}`)
        .then((response) => {
          setIdeaData(response.data)
        })
        .catch((error) => {
          showApiErrorMessage(
            error,
            'Erro ao ler informação sobre a ideia selecionada. Por favor, tente novamente.'
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    handleFetchSingleIdea()
  }, [axiosPrivate, ideaId, showApiErrorMessage, navigate])

  return {
    ideaData,
    setIdeaData,
    isLoading,
  }
}

export default useFetchSingleIdea
