import jwtDecode from 'jwt-decode'
import { TDecodedToken, TTokenData } from '../types/types'
import { TAuth } from '../../context/types/types'
import moment from 'moment'
import 'moment/locale/pt'
import {
  TFilterPagination,
  TSetDataFilter,
} from '../../components/admin/blocks/types/types'
import { sendErrorLogApi } from '../rest'
import { axiosPrivate } from '../../api/axios'

const API_URLS = {
  homepage: '/admin/',
  blocks: '/admin/blocks',
  tenants: '/admin/tenants',
  accounting: '/admin/accounting',
  maintenance: '/admin/maintenance',
  ideas: '/admin/ideas',
  changePassword: '/admin/change-password',
}

const getJwtDataFromLocalStorage = () => {
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refreshToken')

  if (!token || !refreshToken) {
    return undefined
  }

  return jwtDecoder({
    token: token,
    refreshToken: refreshToken,
  })
}

const jwtDecoder = (tokenData: TTokenData): TAuth | undefined => {
  try {
    var decodedToken: TDecodedToken = jwtDecode(tokenData.token)

    localStorage.setItem('token', tokenData.token)
    localStorage.setItem('refreshToken', tokenData.refreshToken)

    return {
      userId: decodedToken.user_id,
      name: decodedToken.name,
      email: decodedToken.username,
      building: decodedToken.building,
      buildingAccId: decodedToken.buildingAccId,
      buildingId: `/api/buildings/${decodedToken.buildingId}`,
      roles: decodedToken.roles,
      token: tokenData.token,
      refreshToken: tokenData.refreshToken,
      expiration: decodedToken.exp,
    }
  } catch (error) {
    sendErrorLogApi(axiosPrivate, error)
  }

  return undefined
}

const invalidateJwtFromLocalStorage = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
}

const convertDateToFullFormat = (date: string | undefined) => {
  if (date === undefined) {
    return '-'
  }

  return moment(date).locale('pt').format('DD/MM/YYYY HH:mm')
}

const convertDateToMonthYearFormat = (date: string | undefined) => {
  if (!date) {
    return 'N/A'
  }

  return moment(date).locale('pt').format('MM/YYYY')
}

const convertDateToYearMonthDayFormat = (date: string) => {
  return moment(date).locale('pt').format('YYYY-MM-DD')
}

const numberFormatting = (value: number | string) => {
  const numberValue = value === -0 || value === '-0' ? 0 : value
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(Number(numberValue))
}

const getIdFromUri = (uri: string) => {
  const individualElements = uri.split('/')

  return individualElements.slice(-1)
}

const getDataFilter = (dataFilters: TSetDataFilter | TFilterPagination) => {
  let filtering = ''
  if (dataFilters.type === 'pagination') {
    filtering = `?pagination=${dataFilters.pagination}&isFullyPaid=0`
  } else {
    filtering = `?page=${dataFilters.page + 1}`
    if (dataFilters.notPaid && !dataFilters.paid) {
      filtering += '&isFullyPaid=0'
    }

    if (!dataFilters.notPaid && dataFilters.paid) {
      filtering += '&isFullyPaid=1'
    }

    if (dataFilters.fromDate) {
      const fromDate = convertDateToYearMonthDayFormat(dataFilters.fromDate)
      filtering += `&createdAt[after]=${fromDate}`
    }

    if (dataFilters.toDate) {
      const toDate = convertDateToYearMonthDayFormat(dataFilters.toDate)
      filtering += `&createdAt[before]=${toDate}`
    }
  }

  return filtering
}

export {
  API_URLS,
  getJwtDataFromLocalStorage,
  jwtDecoder,
  invalidateJwtFromLocalStorage,
  convertDateToFullFormat,
  convertDateToMonthYearFormat,
  convertDateToYearMonthDayFormat,
  numberFormatting,
  getIdFromUri,
  getDataFilter,
}
