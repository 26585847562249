import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'

const useUpdateIdeaStatus = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage, showSystemMessage } = useToastNotification()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleChangeIdeaStatus = async (
    ideaId: number,
    status: number,
    successFn: () => void
  ) => {
    setIsSubmitting(true)

    await axiosPrivate
      .patch(`/api/ideas/${ideaId}/status-update`, {
        status,
      })
      .then(() => {
        successFn()
        showSystemMessage('success', 'Estado da ideia alterado com sucesso.')
      })
      .catch((error) => {
        showApiErrorMessage(
          error,
          'Erro ao atualizar o estado da ideia. Por favor, tente novamente.'
        )
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return {
    handleChangeIdeaStatus,
    isSubmitting,
  }
}

export default useUpdateIdeaStatus
