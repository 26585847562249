import React, { ChangeEvent, useState } from 'react'
import { FilterList, Search } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  TResidentListDataFilter,
  TResidentsListFilterComponent,
} from '../types/types'

const ResidentsListFilter = ({
  setDataFilters,
}: TResidentsListFilterComponent) => {
  const { t } = useTranslation(['home'])
  const [open, setOpen] = useState(false)
  const [activeStatus, setActiveStatus] = useState({
    active: true,
    notActive: true,
  })

  const handleTenantNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget

    setDataFilters((prevFilters: TResidentListDataFilter) => ({
      ...prevFilters,
      page: 0,
      name: value,
    }))
  }

  const handleApplyFiltersAndClose = () => {
    let activeFlag: boolean | null = null
    if (activeStatus.active && !activeStatus.notActive) {
      activeFlag = true
    }

    if (!activeStatus.active && activeStatus.notActive) {
      activeFlag = false
    }

    setDataFilters((prevFilters: TResidentListDataFilter) => ({
      ...prevFilters,
      isActive: activeFlag,
    }))

    setOpen(false)
  }

  const handleResetFilters = () => {
    setActiveStatus({
      active: true,
      notActive: true,
    })
  }

  return (
    <React.Fragment>
      <Box display={'flex'} mt={3} mb={1}>
        <Box ml={'auto'}>
          <TextField
            name='tenantName'
            label={t('admin.management.residents.list.searchTenant')}
            variant='outlined'
            size='small'
            onChange={handleTenantNameChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
            sx={{ mr: 2, width: 250, height: 36 }}
          />
          <Button
            variant='outlined'
            sx={{ height: 40 }}
            onClick={() => setOpen(true)}
          >
            <FilterList />
            &nbsp;
            {t('default.filters')}
          </Button>
        </Box>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('default.filters')}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              <em>{t('default.tenant')}</em>
            </Typography>
            <FormGroup sx={{ display: 'block' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='active'
                    checked={activeStatus.active && true}
                    size='small'
                    onChange={() =>
                      setActiveStatus({
                        ...activeStatus,
                        active: !activeStatus.active,
                      })
                    }
                  />
                }
                label={t('default.active')}
              />
              <FormControlLabel
                sx={{ float: 'right' }}
                control={
                  <Checkbox
                    name='notActive'
                    checked={activeStatus.notActive && true}
                    size='small'
                    onChange={() =>
                      setActiveStatus({
                        ...activeStatus,
                        notActive: !activeStatus.notActive,
                      })
                    }
                  />
                }
                label={t('default.not') + ' ' + t('default.active')}
              />
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            size='small'
            color='error'
            onClick={handleResetFilters}
          >
            Reset
          </Button>
          <Button
            variant='contained'
            size='small'
            color='success'
            onClick={handleApplyFiltersAndClose}
          >
            {t('default.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ResidentsListFilter
