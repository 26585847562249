import React, { MouseEvent, useEffect, useState } from 'react'
import { Resident } from '../../../interfaces/resident'
import LoadingCircular from '../../../components/defaults/Loading'
import { editResidentApi, getResidentsApiData } from '../../../utils/rest'
import useAxiosPrivate from '../../../api/hooks/useAxiosPrivate'
import PageWithoutData from '../PageWithoutData'
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TResidentsList } from '../../../components/admin/blocks/types/types'
import ActiveIcon from '../layout/ActiveIcon'
import { ResidentBlock } from '../../../interfaces/residentblock'
import {
  API_URLS,
  convertDateToMonthYearFormat,
  numberFormatting,
} from '../../../utils/helper/helper'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import { Person, PersonOff } from '@mui/icons-material'
import ResidentsListFilter from '../../../components/admin/residents/list/ResidentsListFilter'
import AddNewResident from '../../../components/admin/residents/add/AddNewResident'
import { TResidentListDataFilter } from '../../../components/admin/residents/types/types'
import {
  getTenantsLastPaymentDate,
  getTenantsRentTotalToPay,
} from '../../../utils/helper/tenantsHelper'
import useToastNotification from '../../../hooks/notifications/useToastNotification'

const TenantsList = () => {
  const axiosPrivate = useAxiosPrivate()
  const { t } = useTranslation(['home'])
  const { showApiErrorMessage, showSystemMessage } = useToastNotification()
  const [residents, setResidents] = useState<Resident[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [errorFetching, setErrorFetching] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [refreshData, setRefreshData] = useState(false)
  const [dataFilters, setDataFilters] = useState<TResidentListDataFilter>({
    page: 0,
    name: '',
    isActive: null,
  })

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setDataFilters({
      ...dataFilters,
      page: newPage,
    })
  }

  const handleResidentStatusChange = async (
    event: MouseEvent<HTMLButtonElement>,
    residentData: Resident
  ) => {
    const hasActiveBlock = residentData.residentBlocks.some((el) => el.isActive)
    if (hasActiveBlock) {
      showSystemMessage('error', t('errors.deactivateTenant'))

      return
    }

    const apiResponse = await editResidentApi(
      axiosPrivate,
      {
        isActive: !residentData.isActive,
      },
      residentData.id
    )
    if (apiResponse.success) {
      setRefreshData(!refreshData)
    } else {
      showApiErrorMessage(apiResponse.data, 'erro')
    }
  }

  useEffect(() => {
    const fetchResidents = async () => {
      setLoading(true)

      const apiResponse: TResidentsList = await getResidentsApiData(
        axiosPrivate,
        dataFilters
      )
      if (!apiResponse.success) {
        setErrorFetching(true)
      } else {
        setResidents(apiResponse.data)
        setTotalItems(apiResponse.totalItems ?? 0)
      }

      setLoading(false)
    }

    fetchResidents()
  }, [dataFilters, refreshData, axiosPrivate])

  return (
    <React.Fragment>
      {errorFetching ? (
        <PageWithoutData />
      ) : (
        <React.Fragment>
          <Box display={'flex'}>
            <Typography variant='h2' className='content-title'>
              {t('admin.management.residents.list.title')}
            </Typography>

            <AddNewResident setRefreshData={setRefreshData} />
          </Box>
          <ResidentsListFilter setDataFilters={setDataFilters} />

          {loading && <LoadingCircular />}
          <TableContainer
            component={Paper}
            sx={{ opacity: `${loading ? 0.2 : 1}` }}
          >
            <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('default.name')}</TableCell>
                  <TableCell>Info</TableCell>
                  <TableCell>
                    {t('admin.management.residents.list.onlineAccount')}
                  </TableCell>
                  <TableCell>{t('default.fractions')}</TableCell>
                  <TableCell>{t('default.balance')}</TableCell>
                  <TableCell>
                    {t('admin.management.blocks.list.condominiumPayment')}
                  </TableCell>
                  <TableCell sx={{ width: 150 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {residents.length > 0 &&
                  residents.map((resident: Resident) => {
                    return (
                      <TableRow
                        key={resident.id}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor: `${
                            resident.isActive ? 'inherit' : '#fdf5f5'
                          }`,
                        }}
                      >
                        <TableCell>{resident.name}</TableCell>
                        <TableCell>
                          <Box>
                            <b>NIF:</b> {resident.nif}
                          </Box>
                          <Box sx={{ color: 'grey', fontSize: '12px' }}>
                            {resident.phone}
                          </Box>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'center' }}>
                          <ActiveIcon isActive={resident.user ? true : false} />
                        </TableCell>
                        <TableCell>
                          {resident.residentBlocks.length === 0
                            ? '-'
                            : resident.residentBlocks.map(
                                (residentBlock: ResidentBlock) => {
                                  return (
                                    <div key={residentBlock.id}>
                                      {residentBlock.isActive ? (
                                        `${residentBlock.block.lotLetter}) ${
                                          residentBlock.block.lotNumber
                                        }
                                        (${t('default.active')})`
                                      ) : (
                                        <Box sx={{ color: 'grey' }}>
                                          <s>
                                            {residentBlock.block.lotLetter}
                                            {`) `}
                                            {residentBlock.block.lotNumber}
                                          </s>{' '}
                                          {convertDateToMonthYearFormat(
                                            residentBlock.disabledAt
                                          )}
                                        </Box>
                                      )}
                                    </div>
                                  )
                                }
                              )}
                        </TableCell>
                        <TableCell>
                          <Box>
                            <b style={{ color: 'green' }}>
                              {t('default.credit')}:
                            </b>{' '}
                            {numberFormatting(resident.balance)}
                          </Box>
                          <Box>
                            <b style={{ color: 'red' }}>
                              {t('default.toPay')}:
                            </b>{' '}
                            {getTenantsRentTotalToPay(resident.residentBlocks)}{' '}
                            {resident.residentBlocks.length > 1 ? '*' : ''}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <b>
                            {t('admin.management.blocks.list.lastPayment')}:
                          </b>{' '}
                          {getTenantsLastPaymentDate(resident.residentBlocks)}
                        </TableCell>
                        <TableCell>
                          <Button
                            href={`${API_URLS.tenants}/${resident.id}`}
                            variant='outlined'
                            fullWidth
                            size='small'
                            color='primary'
                            sx={{
                              pt: 0,
                              pb: 0,
                            }}
                          >
                            <EditNoteOutlinedIcon />
                            &nbsp;{t('default.edit')}
                          </Button>
                          <Button
                            variant='outlined'
                            size='small'
                            sx={{ pt: 0, pb: 0, mt: 1 }}
                            fullWidth
                            color={resident.isActive ? 'error' : 'success'}
                            onClick={(e) =>
                              handleResidentStatusChange(e, resident)
                            }
                          >
                            {resident.isActive ? (
                              <React.Fragment>
                                <PersonOff />
                                &nbsp;{t('default.deActivate')}
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Person />
                                &nbsp;{t('default.activate')}
                              </React.Fragment>
                            )}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ width: '100%' }}>
                  <TablePagination
                    count={totalItems}
                    rowsPerPage={10}
                    page={dataFilters.page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage=''
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default TenantsList
