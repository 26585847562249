import React from 'react'
import Box from '@mui/material/Box'
import LeftMenu from './LeftMenu'
import Navbar from './Navbar'
import Content from './Content'

const AdminLayout = () => {
  return (
    <React.Fragment>
      <Box>
        <Navbar />
      </Box>
      <Box
        style={{
          display: 'flex',
          height: `calc(100vh - 64px)`,
        }}
      >
        <LeftMenu />
        <Content />
      </Box>
    </React.Fragment>
  )
}

export default AdminLayout
