import React, { useState } from 'react'
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  API_URLS,
  convertDateToFullFormat,
  numberFormatting,
} from '../../../utils/helper/helper'
import { Maintenance } from '../../../interfaces/maintenance'
import useFetchMaintenances from '../../../hooks/maintenance/useFetchMaintenances'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { Cancel, CheckCircle } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { MAINTENANCE_TYPES } from './AddMaintenance'

const MaintenanceList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['home'])
  const [year, setYear] = useState<string>('2024')
  const { isLoading, maintenances, handleFetchMaintenances } =
    useFetchMaintenances(year)
  const [maintenanceType, setMaintenanceType] = useState<string>('')
  let totalMaintenance = 0

  const handleChangeMaintenanceType = (type: string) => {
    setMaintenanceType(type)
    handleFetchMaintenances(type)
  }

  return (
    <React.Fragment>
      <Box display={'flex'}>
        <Typography variant='h2' className='content-title'>
          Manutenção
        </Typography>

        <Box ml={'auto'}>
          <Button
            color='warning'
            variant='contained'
            onClick={() => navigate(`${API_URLS.maintenance}/new`)}
          >
            Adicionar Manutenção
          </Button>
        </Box>
      </Box>

      <Box textAlign={'right'} mb={1} mt={1}>
        <FormControl sx={{ mr: 1, minWidth: 250 }}>
          <InputLabel id='maintenanceType' size='small'>
            Tipo de Manutenção
          </InputLabel>
          <Select
            name='maintenanceType'
            labelId='maintenanceType'
            label='Tipo de Manutenção'
            size='small'
            sx={{ textAlign: 'left' }}
            value={maintenanceType}
            onChange={(e) => handleChangeMaintenanceType(e.target.value)}
          >
            <MenuItem disabled value='-1'>
              <em>Selecione o tipo de manutencao</em>
            </MenuItem>
            <MenuItem value=''>Todos os tipos</MenuItem>
            {MAINTENANCE_TYPES.map((maintenance) => (
              <MenuItem key={maintenance.id} value={maintenance.id}>
                {maintenance.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DatePicker
          views={['year']}
          disablePast={true}
          label='Ano Fiscal'
          format='YYYY'
          value={year !== '' ? moment(year) : null}
          onChange={(newValue) =>
            setYear(newValue ? moment(newValue).format('YYY') : '2024')
          }
          slotProps={{
            textField: {
              name: 'Ano Fiscal',
              size: 'small',
              error: false,
              variant: 'outlined',
              required: true,
            },
          }}
        />
      </Box>

      <TableContainer
        component={Paper}
        sx={{ opacity: `${isLoading ? 0.2 : 1}` }}
      >
        <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
          <TableHead>
            <TableRow>
              <TableCell>Ano Fiscal</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Valor total</TableCell>
              <TableCell>Adicionado em</TableCell>
              <TableCell>Divisão pelos condóminos</TableCell>
              <TableCell>Notas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {maintenances.length > 0 &&
              maintenances.map((maintenance: Maintenance) => {
                // Anual quotes do not count as expense
                if (maintenance.type !== 1) {
                  totalMaintenance += maintenance.amount
                }
                return (
                  <TableRow key={maintenance.id}>
                    <TableCell>{maintenance.financialYear}</TableCell>
                    <TableCell>{maintenance.description}</TableCell>
                    <TableCell>
                      {numberFormatting(maintenance.amount)}
                    </TableCell>
                    <TableCell>
                      {convertDateToFullFormat(maintenance.createdAt)}
                    </TableCell>
                    <TableCell>
                      {maintenance.isSplitMaintenance ? (
                        <CheckCircle color='success' />
                      ) : (
                        <Cancel color='error' />
                      )}
                    </TableCell>
                    <TableCell>{maintenance.notes}</TableCell>
                  </TableRow>
                )
              })}
            <TableRow sx={{ backgroundColor: '#f4f4f5' }}>
              <TableCell
                component='th'
                scope='row'
                colSpan={2}
                sx={{ fontWeight: 700, padding: '8px 16px' }}
              >
                {t('admin.management.blocks.list.summary')}
              </TableCell>
              <TableCell
                component='th'
                scope='row'
                colSpan={4}
                sx={{ fontWeight: 700, padding: '8px 16px' }}
              >
                Total Manutenção
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component='th' scope='row' colSpan={2}>
                {maintenanceType === ''
                  ? 'Todos as Manutenções ( exceto quota anual )'
                  : MAINTENANCE_TYPES.filter(
                      (maintenance) =>
                        maintenance.id === Number(maintenanceType)
                    )[0].label}
              </TableCell>
              <TableCell component='th' scope='row' colSpan={4}>
                &nbsp;{numberFormatting(totalMaintenance)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default MaintenanceList
