import React, { useEffect, useState } from 'react'
import { getResidentsWithLatePaymentsApi } from '../../../../utils/rest'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import LatePaymentsTransferList from './LatePaymentsTransferList'
import useToastNotification from '../../../../hooks/notifications/useToastNotification'

type LatePayment = {
  id: number
  name: string
}

export type TResidentsWithLatePaymentsList = {
  success: boolean
  data: LatePayment[] | []
}

const AllLatePayments = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const { t } = useTranslation(['home'])
  const [openSelectDropdown, setOpenSelectDropdown] = useState(false)
  const [selectedResident, setSelectedResident] = useState<string>('')
  const [residentsWithLatePayments, setResidentsWithLatePayments] = useState<
    LatePayment[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const loadResidentsWithLatePayments = async () => {
      setIsLoading(true)
      const latePaymentsApiResponse: TResidentsWithLatePaymentsList =
        await getResidentsWithLatePaymentsApi(axiosPrivate)

      if (!latePaymentsApiResponse.success) {
        showApiErrorMessage(
          latePaymentsApiResponse.data,
          t('errors.getDataError')
        )
      } else {
        setResidentsWithLatePayments(latePaymentsApiResponse.data)
      }

      setIsLoading(false)
    }
    loadResidentsWithLatePayments()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id='demo-controlled-open-select-label' size='small'>
            {t('default.tenant')}
          </InputLabel>
          <Select
            labelId='demo-controlled-open-select-label'
            open={openSelectDropdown}
            onClose={() => setOpenSelectDropdown(false)}
            onOpen={() => setOpenSelectDropdown(true)}
            value={selectedResident}
            label={t('default.tenant')}
            onChange={(e) => setSelectedResident(e.target.value)}
            size='small'
            fullWidth
          >
            {isLoading && <CircularProgress />}

            {residentsWithLatePayments.length === 0 && (
              <MenuItem value=''>
                Não existem condóminos com pagamentos em atraso
              </MenuItem>
            )}

            {residentsWithLatePayments.length > 0 && (
              <MenuItem disabled value=''>
                <em>
                  {t('admin.management.blocks.edit.selectResidentPlaceholder')}
                </em>
              </MenuItem>
            )}
            {residentsWithLatePayments.length > 0 &&
              residentsWithLatePayments.map((paymentqueue, idx) => (
                <MenuItem key={idx} value={paymentqueue.id}>
                  {paymentqueue.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        {selectedResident && (
          <LatePaymentsTransferList selectedResident={selectedResident} />
        )}
      </Grid>
    </Grid>
  )
}

export default AllLatePayments
