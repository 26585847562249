import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  getActiveResidentsApiData,
  getSingleBlocksApiData,
} from '../../../utils/rest'
import LoadingCircular from '../../../components/defaults/Loading'
import PageNotFound from '../PageNotFound'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BlockInfo from '../../../components/admin/blocks/edit/BlockInfo'
import BlockUpdateInfo from '../../../components/admin/blocks/edit/BlockUpdateInfo'
import BlockPaymentsList from '../../../components/admin/blocks/edit/BlockPaymentsList'
import BlockOldResidentsList from '../../../components/admin/blocks/edit/BlockOldResidentsList'
import { Resident } from '../../../interfaces/resident'
import { Block } from '../../../interfaces/block'
import PageWithoutData from '../PageWithoutData'
import useAxiosPrivate from '../../../api/hooks/useAxiosPrivate'
import useToastNotification from '../../../hooks/notifications/useToastNotification'

const BlockDetails = () => {
  const { id } = useParams()
  const { t } = useTranslation(['home'])
  const { showApiErrorMessage } = useToastNotification()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const [blockData, setBlockData] = useState<Block | undefined>(undefined)
  const [residentsData, setResidentsData] = useState<Resident[]>([])
  const [errors, setErrors] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tabSelected, setTabSelected] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue)
  }

  useEffect(() => {
    if (id === undefined) {
      navigate(-1)
    }

    const getBlockById = async () => {
      await Promise.all([
        getSingleBlocksApiData(axiosPrivate, id),
        getActiveResidentsApiData(axiosPrivate),
      ])
        .then((response) =>
          response.map((apiData: any, idx) =>
            !apiData.success
              ? setErrors(true)
              : idx === 0
              ? setBlockData(apiData.data)
              : setResidentsData(apiData.data)
          )
        )
        .catch((err) => {
          showApiErrorMessage(
            err,
            'Não foi possível carregar os dados. Refresque a página e tente novamente.'
          )
          setErrors(true)
        })

      setLoading(false)
    }

    getBlockById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      {loading ? (
        <LoadingCircular />
      ) : errors ? (
        <PageNotFound />
      ) : blockData === undefined ? (
        <PageWithoutData showButton={true} />
      ) : (
        <React.Fragment>
          <Typography
            variant='h2'
            sx={{
              fontWeight: 700,
              fontSize: '1.5rem',
              lineHeight: '1.375',
              mb: 2,
            }}
          >
            {t('admin.management.blocks.edit.title')} {blockData.lotNumber}
          </Typography>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <BlockInfo
                  blockData={blockData}
                  residentsData={residentsData}
                />
              </Grid>
              <Grid item xs={5}>
                <BlockUpdateInfo blockData={blockData} />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{ width: '350px', margin: '50px auto', p: 0 }}
            className='box-shadow'
          >
            <Tabs value={tabSelected} onChange={handleTabChange} centered>
              <Tab label={t('default.payments')} />
              <Tab label={t('admin.management.blocks.edit.oldTenants.title')} />
            </Tabs>
          </Box>

          {tabSelected === 0 && (
            <BlockPaymentsList residentBlock={blockData.residentBlocks[0]} />
          )}
          {tabSelected === 1 && (
            <BlockOldResidentsList blockId={blockData.id} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default BlockDetails
