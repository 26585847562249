import React, { useEffect, useState } from 'react'
import { getOldResidentsFromBlockApiData } from '../../../../utils/rest'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import { TBlockOldResidentsList, TOldResidentsList } from '../types/types'
import { ResidentBlock } from '../../../../interfaces/residentblock'
import LoadingCircular from '../../../defaults/Loading'
import PageWithoutData from '../../../../pages/admin/PageWithoutData'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  convertDateToFullFormat,
  numberFormatting,
} from '../../../../utils/helper/helper'
import { PaymentQueue } from '../../../../interfaces/paymentqueue'

export default function BlockOldResidentsList({
  blockId,
}: TBlockOldResidentsList) {
  const axiosPrivate = useAxiosPrivate()
  const { t } = useTranslation(['home'])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [oldBlockResidents, setOldBlockResidents] = useState<ResidentBlock[]>(
    []
  )

  const checkResidentPaymentStatus = (blockResident: ResidentBlock) => {
    let paymentInfo = {
      totalPaid: 0,
      totalToPay: 0,
    }

    if (blockResident.paymentQueues) {
      blockResident.paymentQueues.map((payment: PaymentQueue) => {
        if (payment.isFullyPaid) {
          paymentInfo.totalPaid += payment.amount
        } else {
          paymentInfo.totalToPay += payment.amount - payment.amountAlreadyPaid
        }

        return ''
      })
    }

    return paymentInfo
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  useEffect(() => {
    const fetchOldResidents = async () => {
      setLoading(true)

      const apiResponse: TOldResidentsList =
        await getOldResidentsFromBlockApiData(axiosPrivate, blockId, page)

      if (apiResponse.data && apiResponse.totalItems) {
        setOldBlockResidents(apiResponse.data)
        setTotalItems(apiResponse.totalItems)
      }

      setLoading(false)
    }

    fetchOldResidents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <React.Fragment>
      {loading ? (
        <LoadingCircular />
      ) : oldBlockResidents.length === 0 ? (
        <PageWithoutData />
      ) : (
        <React.Fragment>
          <Typography variant='h2' className='content-title'>
            {t('admin.management.blocks.edit.oldTenants.title')}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('default.name')}</TableCell>
                  <TableCell>{t('default.balance')}</TableCell>
                  <TableCell>{t('default.createdAt')}</TableCell>
                  <TableCell>{t('default.disabledAt')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {oldBlockResidents.map((blockResident: ResidentBlock) => {
                  const residentPaymentStatus =
                    checkResidentPaymentStatus(blockResident)

                  return (
                    <TableRow
                      key={blockResident.id}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: `${
                          blockResident.isActive ? 'lightyellow' : 'inherit'
                        }`,
                      }}
                    >
                      <TableCell component='th' scope='row'>
                        {blockResident.resident.name}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <Box>
                          <b style={{ color: 'green' }}>{t('default.paid')}:</b>{' '}
                          {residentPaymentStatus.totalPaid > 0
                            ? numberFormatting(residentPaymentStatus.totalPaid)
                            : '-'}
                        </Box>
                        <Box>
                          <b style={{ color: 'red' }}>{t('default.toPay')}:</b>{' '}
                          {residentPaymentStatus.totalToPay > 0
                            ? numberFormatting(residentPaymentStatus.totalToPay)
                            : '-'}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {convertDateToFullFormat(blockResident.createdAt)}
                      </TableCell>
                      <TableCell>
                        {blockResident.isActive ? (
                          <b>
                            {t(
                              'admin.management.blocks.edit.oldTenants.currentActive'
                            )}
                          </b>
                        ) : (
                          convertDateToFullFormat(blockResident.disabledAt)
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ width: '100%' }}>
                  <TablePagination
                    count={totalItems}
                    rowsPerPage={10}
                    page={page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage=''
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
