import { useContext, useEffect } from 'react'
import { axiosPrivate } from '../axios'
import useRefreshToken from './useRefreshToken'
import { TRefreshToken } from '../types/types'
import { AuthContext } from '../../context/AuthProvider'
import { invalidateJwtFromLocalStorage } from '../../utils/helper/helper'

const useAxiosPrivate = () => {
  const { auth, setAuth } = useContext(AuthContext)
  const refresh = useRefreshToken()

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${auth?.token}`
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config
        if (
          error?.response?.data.code === 401 &&
          (error?.response?.data.message === 'Invalid JWT Token' ||
            error?.response?.data.message === 'Expired JWT Token')
        ) {
          if (!prevRequest?.sent) {
            prevRequest.sent = true
            const newAccessToken: TRefreshToken = await refresh()
            if (newAccessToken === null) {
              setAuth(undefined)
              invalidateJwtFromLocalStorage()
            } else {
              prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`
              return axiosPrivate(prevRequest)
            }
          } else {
            setAuth(undefined)
            invalidateJwtFromLocalStorage()
          }
        }

        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept)
      axiosPrivate.interceptors.response.eject(responseIntercept)
    }
  })

  return axiosPrivate
}

export default useAxiosPrivate
