import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import { getPaymentQueueForSpecificResidentApiData } from '../../../../utils/rest'
import { PaymentQueue } from '../../../../interfaces/paymentqueue'
import { useTranslation } from 'react-i18next'
import { numberFormatting } from '../../../../utils/helper/helper'
import { Alert, Box, FormControlLabel, FormGroup, Switch } from '@mui/material'
import useToastNotification from '../../../../hooks/notifications/useToastNotification'
import useAddUserLateQuotesPayment from '../../../../hooks/payments/useAddUserLatePayment'
import LatePaymentConfirmation from './LatePaymentConfirmation'

function not(a: readonly PaymentQueue[], b: readonly PaymentQueue[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a: readonly PaymentQueue[], b: readonly PaymentQueue[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a: readonly PaymentQueue[], b: readonly PaymentQueue[]) {
  return [...a, ...not(b, a)]
}

const LatePaymentsTransferList = ({
  selectedResident,
}: {
  selectedResident: string
}) => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const {
    handleAddUserALatePayment,
    isSubmitting,
    paymentDetails,
    handleDownloadFile,
  } = useAddUserLateQuotesPayment()
  const { t } = useTranslation(['home'])
  const [checked, setChecked] = useState<readonly PaymentQueue[]>([])
  const [amountToPay, setAmountToPay] = useState<number>(0)
  const [latePayments, setLatePayments] = useState<readonly PaymentQueue[]>([])
  const [latePaymentsToPay, setLatePaymentsToPay] = useState<
    readonly PaymentQueue[]
  >([])
  const [useResidentBalance, setUseResidentBalance] = useState<boolean>(false)
  const residentBalance = useRef<number>(0)

  const latePaymentsChecked = intersection(checked, latePayments)
  const latePaymentsToPayChecked = intersection(checked, latePaymentsToPay)

  const handleToggle = (value: PaymentQueue) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items: readonly PaymentQueue[]) =>
    intersection(checked, items).length

  const handleToggleAll = (items: readonly PaymentQueue[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedLatePaymentsToPay = () => {
    setLatePaymentsToPay(latePaymentsToPay.concat(latePaymentsChecked))
    setLatePayments(not(latePayments, latePaymentsChecked))
    setChecked(not(checked, latePaymentsChecked))
  }

  const handleCheckedLatePayments = () => {
    setLatePayments(latePayments.concat(latePaymentsToPayChecked))
    setLatePaymentsToPay(not(latePaymentsToPay, latePaymentsToPayChecked))
    setChecked(not(checked, latePaymentsToPayChecked))
  }

  const handleSubmitPayment = async () => {
    const payload = {
      resident: selectedResident,
      payments: latePaymentsToPay.map((payment) => ({ id: payment.id })),
      useResidentBalance,
      totalAmount: Number(getTotalAmountToPay(false)),
    }

    handleAddUserALatePayment(payload)
  }

  const getTotalAmountToPay = (incFormatting = true) => {
    let total = amountToPay

    if (useResidentBalance) {
      total -= residentBalance.current

      if (total < 0) {
        total = 0
      }
    }

    if (!incFormatting) {
      return total
    }

    return numberFormatting(total)
  }

  useEffect(() => {
    const getListOfLatePayment = async () => {
      const apiResponse = await getPaymentQueueForSpecificResidentApiData(
        axiosPrivate,
        selectedResident,
        {
          type: 'pagination',
          pagination: false,
        }
      )

      if (!apiResponse.success) {
        showApiErrorMessage(apiResponse.data, t('errors.getDataError'))
      } else {
        setLatePayments(apiResponse.data)
        setLatePaymentsToPay([])

        if (apiResponse.data.length > 0) {
          residentBalance.current =
            apiResponse.data[0].residentBlock.resident.balance
        }
      }
    }

    getListOfLatePayment()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResident])

  useEffect(() => {
    let toPay = 0
    latePaymentsToPay.map(
      (payment: PaymentQueue) =>
        (toPay += payment.amount - payment.amountAlreadyPaid)
    )

    setAmountToPay(toPay)
  }, [latePaymentsToPay])

  const customList = (
    title: React.ReactNode,
    items: readonly PaymentQueue[]
  ) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'Todos os pagamentos selecionados',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
      />
      <Divider />
      <List
        sx={{
          width: 400,
          height: 500,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map((paymentQueue: PaymentQueue) => {
          const labelId = `transfer-list-all-item-${paymentQueue.id}-label`

          return (
            <ListItem
              key={paymentQueue.id}
              role='listitem'
              button
              onClick={handleToggle(paymentQueue)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(paymentQueue) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId}>
                <b>Fração {paymentQueue.residentBlock?.block.lotNumber}:</b>{' '}
                {paymentQueue.description} (
                {numberFormatting(
                  paymentQueue.amount - paymentQueue.amountAlreadyPaid
                )}
                )
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
    </Card>
  )

  return (
    <React.Fragment>
      {paymentDetails !== undefined ? (
        <LatePaymentConfirmation
          paymentData={paymentDetails}
          residentBalance={residentBalance.current}
          useResidentBalance={useResidentBalance}
          forceDownloadFile={handleDownloadFile}
        />
      ) : (
        <React.Fragment>
          <Grid container spacing={2} mt={1} mb={1}>
            <Grid item>{customList('Pagamentos em atraso', latePayments)}</Grid>
            <Grid item>
              <Grid container direction='column' alignItems='center'>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleCheckedLatePaymentsToPay}
                  disabled={latePaymentsChecked.length === 0}
                  aria-label='move selected latePaymentsToPay'
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleCheckedLatePayments}
                  disabled={latePaymentsToPayChecked.length === 0}
                  aria-label='move selected latePayments'
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList('A pagar', latePaymentsToPay)}</Grid>
          </Grid>
          {residentBalance.current > 0 && (
            <Grid item xs={12} mt={2}>
              <Alert
                severity='warning'
                sx={{
                  '& .MuiAlert-message': {
                    overflow: 'unset',
                  },
                }}
              >
                <Box fontWeight={700}>
                  O condómino tem um saldo{' '}
                  {new Intl.NumberFormat('pt-PT', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(residentBalance.current)}
                </Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name='isActive'
                        checked={useResidentBalance}
                        onChange={() =>
                          setUseResidentBalance(!useResidentBalance)
                        }
                      />
                    }
                    labelPlacement='start'
                    label={'Utilizar saldo do condómino'}
                    sx={{
                      justifyContent: 'left',
                      ml: 0,
                      '& .MuiTypography-root': {
                        fontSize: '0.875rem',
                      },
                    }}
                  />
                </FormGroup>
              </Alert>
            </Grid>
          )}
          <Box sx={{ fontSize: 30, mt: 2, mb: 3 }}>
            Valor a receber: <b>{getTotalAmountToPay()}</b>
          </Box>
          <Button
            variant='contained'
            onClick={handleSubmitPayment}
            disabled={latePaymentsToPay.length === 0 || isSubmitting}
          >
            Submeter pedido
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default LatePaymentsTransferList
