import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useDownloadInvoice from '../pdf/useDownloadFile'
import useToastNotification from '../notifications/useToastNotification'

type PaymentData = {
  residentBlockId: string
  amount: number
  useBalance: boolean
}

type ResponsePaidAndToPay = {
  id: number
  description: string
  amount: number
}

type ResponseFile = {
  fileName: string
}

export type AutoPaymentResponseData = {
  paid: ResponsePaidAndToPay[]
  toPay: ResponsePaidAndToPay[]
  file: ResponseFile
  newResidentBalance: number
}

const useAddUserAutoPayment = () => {
  const axiosPrivate = useAxiosPrivate()
  const { handleDownloadFile } = useDownloadInvoice()
  const { showApiErrorMessage } = useToastNotification()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [paymentDetails, setPaymentDetails] = useState<
    AutoPaymentResponseData | undefined
  >(undefined)

  const handleAddUserAutoPayment = async (paymentData: PaymentData) => {
    setIsSubmitting(true)

    await axiosPrivate
      .post('/api/payment-queues/automatic-quotes-payment', { ...paymentData })
      .then((response) => {
        const apiResponse: AutoPaymentResponseData = response.data
        if (apiResponse.file.fileName !== '') {
          handleDownloadFile(apiResponse.paid[0].id, apiResponse.file.fileName)
        }

        setPaymentDetails(apiResponse)
      })
      .catch((error) => {
        showApiErrorMessage(error, 'Erro ao adicionar pagamento automático.')
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const forceDownloadFile = () => {
    if (paymentDetails && paymentDetails.file.fileName !== '') {
      handleDownloadFile(
        paymentDetails.paid[0].id,
        paymentDetails.file.fileName
      )
    }
  }

  return {
    isSubmitting,
    handleAddUserAutoPayment,
    paymentDetails,
    forceDownloadFile,
  }
}

export default useAddUserAutoPayment
