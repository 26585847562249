import React, { useContext, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import MoreOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import { AuthContext } from '../../../context/AuthProvider'
import image from '../../../images/predio3.png'
import { grey } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom'
import { API_URLS } from '../../../utils/helper/helper'

//TODO: Mobile version commented out for now - not sure if will have time for it for MVP
const Navbar = () => {
  const { auth, setAuth } = useContext(AuthContext)
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null)

  const isMenuOpen = Boolean(anchorEl)
  //   const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    setAuth(undefined)

    navigate('/login')
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem
        onClick={() => {
          handleMenuClose()
          navigate(API_URLS.changePassword)
        }}
      >
        Mudar Password
      </MenuItem>
      <MenuItem onClick={handleLogout}>Sair</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  //   const renderMobileMenu = (
  //     <Menu
  //       anchorEl={mobileMoreAnchorEl}
  //       anchorOrigin={{
  //         vertical: 'top',
  //         horizontal: 'right',
  //       }}
  //       id={mobileMenuId}
  //       keepMounted
  //       transformOrigin={{
  //         vertical: 'top',
  //         horizontal: 'right',
  //       }}
  //       open={isMobileMenuOpen}
  //       onClose={handleMobileMenuClose}
  //     >
  //       <MenuItem>
  //         <IconButton
  //           size='large'
  //           aria-label='show 4 new mails'
  //           color='secondary'
  //         >
  //           <Badge badgeContent={4} color='error'>
  //             <MailOutlineOutlinedIcon />
  //           </Badge>
  //         </IconButton>
  //         <p>Messages</p>
  //       </MenuItem>
  //       <MenuItem>
  //         <IconButton
  //           size='large'
  //           aria-label='show 17 new notifications'
  //           color='secondary'
  //         >
  //           <Badge badgeContent={17} color='error'>
  //             <NotificationsOutlinedIcon />
  //           </Badge>
  //         </IconButton>
  //         <p>Notifications</p>
  //       </MenuItem>
  //       <MenuItem onClick={handleProfileMenuOpen}>
  //         <IconButton
  //           size='large'
  //           aria-label='account of current user'
  //           aria-controls='primary-search-account-menu'
  //           aria-haspopup='true'
  //           color='secondary'
  //         >
  //           <AccountCircle />
  //         </IconButton>
  //         <p>Profile</p>
  //       </MenuItem>
  //     </Menu>
  //   )

  return (
    <React.Fragment>
      <AppBar position='static' sx={{ backgroundColor: 'white' }}>
        <Toolbar>
          <Typography
            variant='h6'
            noWrap
            component='div'
            color='black'
            sx={{
              height: '37px',
              display: 'inline-flex',
              alignItems: 'center',
              fontWeight: '700',
            }}
          >
            <img
              src={image}
              alt=''
              style={{
                objectFit: 'contain',
                height: '100%',
                marginRight: '5px',
              }}
            />
            {auth?.building}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              size='large'
              sx={{ color: grey[500] }}
              onClick={() =>
                alert('Apenas será implementado na segunda fase do projecto')
              }
            >
              <Badge badgeContent={0} color='error'>
                {/* //TODO: needs to be done */}
                <MailOutlineOutlinedIcon />
              </Badge>
            </IconButton>
            <IconButton
              size='large'
              sx={{ color: grey[500] }}
              onClick={() =>
                alert('Apenas será implementado na segunda fase do projecto')
              }
            >
              <Badge badgeContent={0} color='error'>
                {/* //TODO: needs to be done */}
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
            <IconButton size='large' edge='end' onClick={handleProfileMenuOpen}>
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              onClick={handleMobileMenuOpen}
              color='secondary'
            >
              <MoreOutlinedIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* {renderMobileMenu} */}
      {renderMenu}
    </React.Fragment>
  )
}

export default Navbar
