import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TBlockList } from '../../../../pages/types/types'
import { getBlocksApiData } from '../../../../utils/rest'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import { Block } from '../../../../interfaces/block'
import useAddUserAutoPayment from '../../../../hooks/payments/useAddUserAutoPayment'
import useToastNotification from '../../../../hooks/notifications/useToastNotification'
import AutoPaymentConfirmation from './AutoPaymentConfirmation'

const QuotesByReceivedAmount = () => {
  const axiosPrivate = useAxiosPrivate()
  const { t } = useTranslation(['home'])
  const { showApiErrorMessage } = useToastNotification()
  const {
    handleAddUserAutoPayment,
    paymentDetails,
    isSubmitting,
    forceDownloadFile,
  } = useAddUserAutoPayment()
  const [openSelectDropdown, setOpenSelectDropdown] = useState(false)
  const [blocks, setBlocks] = useState<Block[]>([])
  const [residentBalance, setResidentBalance] = useState<number>(0)
  const [useResidentBalance, setUseResidentBalance] = useState<boolean>(false)
  const [selectedResident, setSelectedResident] = useState<string>('')
  const [amountReceived, setAmountReceived] = useState<string>('')

  const handlePayment = () => {
    handleAddUserAutoPayment({
      residentBlockId: selectedResident,
      amount: Number(amountReceived),
      useBalance: useResidentBalance,
    })
  }

  useEffect(() => {
    const loadBlocks = async () => {
      const blockApiResponse: TBlockList = await getBlocksApiData(axiosPrivate)
      if (!blockApiResponse.success) {
        showApiErrorMessage(blockApiResponse.data, t('errors.getDataError'))
      } else {
        setBlocks(blockApiResponse.data)
      }
    }

    loadBlocks()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      {isSubmitting ? (
        <CircularProgress />
      ) : paymentDetails !== undefined ? (
        <AutoPaymentConfirmation
          paymentData={paymentDetails}
          amount={amountReceived}
          residentBalance={residentBalance}
          useResidentBalance={useResidentBalance}
          forceDownloadFile={forceDownloadFile}
        />
      ) : (
        <Grid container>
          <Grid item xs={12} mb={3}>
            <Alert severity='info'>
              Neste sistema de pagamento de quotas automático, o valor
              introduzido (valor que for indicado no campo do valor recebido em
              €), será o valor utilizado para cálculo e pagamento de quotas (as
              que estejam em atraso e de futuras dentro do ano fiscal corrente).
              <p>
                Se depois do cálculo sobrar algum montante que já não de para
                pagamento de nenhuma quota, o mesmo será adicionado ao crédito
                do condómino.
              </p>
              <p>
                <b>
                  <p>
                    Este sistema de pagamento automático de quotas, apenas se
                    aplica a condóminos que estejam associados a uma fração.
                  </p>
                  <p>
                    Para pagamentos de quotas de antigas frações ou outro tipo,
                    seleccione o método de pagamento "Pagamentos em atraso"
                  </p>
                </b>
              </p>
            </Alert>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id='demo-controlled-open-select-label' size='small'>
                {t('default.tenant')}
              </InputLabel>
              <Select
                labelId='demo-controlled-open-select-label'
                open={openSelectDropdown}
                onClose={() => setOpenSelectDropdown(false)}
                onOpen={() => setOpenSelectDropdown(true)}
                value={selectedResident}
                label={t('default.tenant')}
                onChange={(e) => {
                  setSelectedResident(e.target.value)
                  setResidentBalance(
                    blocks.find((b) => b.id === Number(e.target.value))
                      ?.residentBlocks[0].resident.balance || 0
                  )
                  setUseResidentBalance(false)
                  setAmountReceived('')
                }}
                size='small'
                fullWidth
              >
                <MenuItem disabled value=''>
                  <em>
                    {t(
                      'admin.management.blocks.edit.selectResidentPlaceholder'
                    )}
                  </em>
                </MenuItem>
                {blocks.length > 0 &&
                  blocks.map((block, idx) => (
                    <MenuItem key={idx} value={block.id}>
                      {block.lotNumber} ({block.residentBlocks[0].resident.name}
                      )
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {selectedResident && (
            <React.Fragment>
              {residentBalance > 0 && (
                <Grid item xs={12} mt={2}>
                  <Alert
                    severity='warning'
                    sx={{
                      '& .MuiAlert-message': {
                        overflow: 'unset',
                      },
                    }}
                  >
                    <Box fontWeight={700}>
                      O condómino tem um saldo{' '}
                      {new Intl.NumberFormat('pt-PT', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(residentBalance)}
                    </Box>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            name='isActive'
                            checked={useResidentBalance}
                            onChange={() =>
                              setUseResidentBalance(!useResidentBalance)
                            }
                          />
                        }
                        labelPlacement='start'
                        label={'Utilizar saldo do condómino'}
                        sx={{
                          justifyContent: 'left',
                          ml: 0,
                          '& .MuiTypography-root': {
                            fontSize: '0.875rem',
                          },
                        }}
                      />
                    </FormGroup>
                  </Alert>
                </Grid>
              )}

              <Grid item container mt={2}>
                <Grid item xs={4}>
                  <TextField
                    label={'Valor recebido em €'}
                    size='small'
                    fullWidth
                    type='number'
                    inputProps={{
                      step: 0.01,
                      min: 0,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={amountReceived}
                    onChange={(e) => setAmountReceived(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item container mt={2}>
                <Button
                  variant='contained'
                  onClick={handlePayment}
                  disabled={amountReceived === '' || isSubmitting}
                >
                  Efetuar pagamento
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      )}
    </React.Fragment>
  )
}

export default QuotesByReceivedAmount
