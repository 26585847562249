import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Box, Button, Typography } from '@mui/material'
import { getBlocksApiData } from '../../../utils/rest'
import { useTranslation } from 'react-i18next'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import LoadingCircular from '../../../components/defaults/Loading'
import {
  API_URLS,
  convertDateToFullFormat,
  numberFormatting,
} from '../../../utils/helper/helper'
import { Block } from '../../../interfaces/block'
import { TBlockList } from '../../types/types'
import PageWithoutData from '../PageWithoutData'
import useAxiosPrivate from '../../../api/hooks/useAxiosPrivate'

const BlocksList = () => {
  const { t } = useTranslation(['home'])
  const axiosPrivate = useAxiosPrivate()
  const [blockData, setBlockData] = useState<Block[]>([])
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState(false)
  let totalToPayFromBlocks = 0

  useEffect(() => {
    const getUsersList = async () => {
      setLoading(true)

      const blockApiResponse: TBlockList = await getBlocksApiData(axiosPrivate)
      if (!blockApiResponse.success) {
        setErrors(true)
      } else {
        setBlockData(blockApiResponse.data)
      }

      setLoading(false)
    }

    getUsersList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      {loading ? (
        <LoadingCircular />
      ) : errors ? (
        <PageWithoutData />
      ) : (
        <React.Fragment>
          <Typography variant='h2' className='content-title'>
            {t('admin.management.blocks.list.title')}
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('default.fraction')}</TableCell>
                  <TableCell>{t('default.tenant')}</TableCell>
                  <TableCell>Info</TableCell>
                  <TableCell>{t('default.balance')}</TableCell>
                  <TableCell>
                    {t('admin.management.blocks.list.condominiumPayment')}
                  </TableCell>
                  <TableCell sx={{ width: 150 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {blockData.length > 0 &&
                  blockData.map((block: Block) => {
                    const residentInfo = block.residentBlocks[0]
                    totalToPayFromBlocks += residentInfo.rentTotalToPay

                    return (
                      <TableRow key={block.id} sx={{ cursor: 'pointer' }}>
                        <TableCell component='th' scope='row'>
                          <Box>
                            {block.lotLetter}
                            {`) `}
                            {block.lotNumber}
                          </Box>
                          <Box sx={{ color: 'grey', fontSize: '12px' }}>
                            {block.lotFraction}/1000
                          </Box>
                        </TableCell>
                        <TableCell component='th' scope='row'>
                          <Box>{residentInfo.resident.name}</Box>
                          <Box sx={{ color: 'grey', fontSize: '12px' }}>
                            {residentInfo.resident.user
                              ? residentInfo.resident.user.email
                              : t(
                                  'admin.management.blocks.list.noOnlineAccount'
                                )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box>{residentInfo.resident.phone}</Box>
                          <Box sx={{ color: 'grey', fontSize: '12px' }}>
                            <b>NIF:</b> {residentInfo.resident.nif}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box>
                            <b style={{ color: 'green' }}>
                              {t('default.credit')}:
                            </b>{' '}
                            {numberFormatting(residentInfo.resident.balance)}
                          </Box>
                          <Box>
                            <b style={{ color: 'red' }}>
                              {t('default.toPay')}:
                            </b>{' '}
                            {numberFormatting(residentInfo.rentTotalToPay)}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <b>
                            {t('admin.management.blocks.list.lastPayment')}:
                          </b>{' '}
                          {residentInfo.rentLastPayment
                            ? convertDateToFullFormat(
                                residentInfo.rentLastPayment
                              )
                            : 'N/A'}
                        </TableCell>
                        <TableCell>
                          <Button
                            href={`${API_URLS.blocks}/${block.id}`}
                            variant='outlined'
                            fullWidth
                            size='small'
                            color='primary'
                            sx={{
                              pt: 0,
                              pb: 0,
                            }}
                          >
                            <EditNoteOutlinedIcon />
                            &nbsp;{t('default.edit')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                <TableRow sx={{ backgroundColor: '#f4f4f5' }}>
                  <TableCell
                    component='th'
                    scope='row'
                    colSpan={3}
                    sx={{ fontWeight: 700, padding: '8px 16px' }}
                  >
                    {t('admin.management.blocks.list.summary')}
                  </TableCell>
                  <TableCell
                    component='th'
                    scope='row'
                    colSpan={3}
                    sx={{ fontWeight: 700, padding: '8px 16px' }}
                  >
                    {t('admin.management.blocks.list.totalDebt')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th' scope='row' colSpan={3}>
                    {t('default.condominium')} ( todas as manutenções incluídas
                    )
                  </TableCell>
                  <TableCell component='th' scope='row' colSpan={3}>
                    &nbsp;{numberFormatting(totalToPayFromBlocks)}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ backgroundColor: 'lightyellow' }}>
                  <TableCell component='th' scope='row' colSpan={3}>
                    {t('default.works')}
                  </TableCell>
                  <TableCell component='th' scope='row' colSpan={3}>
                    Ainda não está implementado a parte das obras
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default BlocksList
