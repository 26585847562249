import { useState, useEffect, useCallback } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'
import { Ideas } from '../../interfaces/ideas'

const useFetchIdeas = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const [ideas, setIdeas] = useState<Ideas[]>([])
  const [totalIdeas, setTotalIdeas] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFetchMaintenances = useCallback(
    async (page: number, status?: number) => {
      setIsLoading(true)

      let url = `/api/ideas?page=${page + 1}`
      if (status && status > 0) {
        url += `&status=${status}`
      }

      await axiosPrivate
        .get(url)
        .then((response) => {
          setIdeas(response.data['hydra:member'])
          setTotalIdeas(response.data['hydra:totalItems'])
        })
        .catch((error) => {
          showApiErrorMessage(
            error,
            'Erro ao fazer a listagem das ideias / sugestões. Por favor, tente novamente.'
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [axiosPrivate, showApiErrorMessage]
  )

  useEffect(() => {
    handleFetchMaintenances(0)
  }, [handleFetchMaintenances])

  return {
    ideas,
    isLoading,
    handleFetchMaintenances,
    totalIdeas,
  }
}

export default useFetchIdeas
