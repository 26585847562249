import React, { useState } from 'react'
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import useAddMaintenance from '../../../hooks/maintenance/useAddMaintenance'
import useToastNotification from '../../../hooks/notifications/useToastNotification'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'

export const MAINTENANCE_TYPES = [
  { id: 1, label: 'Quota Anual' },
  { id: 2, label: 'Seguro' },
  { id: 3, label: 'Despesas Elevador' },
  { id: 4, label: 'Despesa Mulher Limpeza' },
  { id: 5, label: 'Despesa Luz' },
  { id: 6, label: 'Outros' },
]

const AddMaintenance = () => {
  const { isSubmitting, handleAddMaintenances } = useAddMaintenance()
  const { showSystemMessage } = useToastNotification()
  const [openSelectDropdown, setOpenSelectDropdown] = useState<boolean>(false)
  const [maintenance, setMaintenance] = useState<string>('')
  const [maintenanceDescription, setMaintenanceDescription] =
    useState<string>('')
  const [maintenanceAmount, setMaintenanceAmount] = useState<string>('')
  const [maintenanceNotes, setMaintenanceNotes] = useState<string>('')
  const [maintenanceFinancialYear, setMaintenanceFinancialYear] =
    useState<string>(moment().format('YYYY'))
  const [maintenanceToBeSplit, setMaintenanceToBeSplit] =
    useState<boolean>(false)
  const [maintenanceToBeDivided, setMaintenanceToBeDivided] =
    useState<boolean>(false)
  const [extraInfo, setExtraInfo] = useState<any>([])

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target

    setMaintenanceToBeSplit(false)
    setMaintenanceToBeDivided(false)
    setMaintenanceAmount('')
    setMaintenance(value)
  }

  const handleCreateMaintenance = () => {
    if (maintenance == '6' && maintenanceDescription.trim().length === 0) {
      showSystemMessage('error', 'Por favor insira a descrição da manutenção')
      return
    }

    if (maintenance === '') {
      showSystemMessage('error', 'Por favor selecione o tipo de manutenção')
      return
    }

    if (maintenanceAmount === '') {
      showSystemMessage('error', 'Por favor insira o valor da manutenção')
      return
    }

    if (maintenanceFinancialYear === '') {
      showSystemMessage('error', 'Por favor insira o ano fiscal')
      return
    }

    if (
      maintenance == '1' &&
      (!extraInfo['anualQuote'] || extraInfo['anualQuote'] === '')
    ) {
      showSystemMessage(
        'error',
        'Por favor insira o valor da quota anual para a fração B'
      )
      return
    }

    handleAddMaintenances({
      type: maintenance,
      amount: maintenanceAmount,
      notes: maintenanceNotes,
      financialYear: maintenanceFinancialYear,
      description: maintenanceDescription,
      splitable: maintenanceToBeSplit,
      extraInfo,
      maintenanceToBeDivided,
    })
  }

  return (
    <Grid container xs={6} gap={2} className='box-shadow'>
      <Typography variant='h2' className='content-title'>
        Adicionar Manutenção
      </Typography>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id='tipo-de-manutencao' size='small'>
            Selecionar o tipo de manutenção
          </InputLabel>
          <Select
            labelId='tipo-de-manutencao'
            open={openSelectDropdown}
            onClose={() => setOpenSelectDropdown(false)}
            onOpen={() => setOpenSelectDropdown(true)}
            label={'Selecionar o tipo de manutenção'}
            onChange={handleChange}
            value={maintenance}
            size='small'
            fullWidth
          >
            <MenuItem disabled value='placeholder'>
              <em>Selecione o tipo de manutenção</em>
            </MenuItem>
            {MAINTENANCE_TYPES.map((despesa, idx) => (
              <MenuItem key={idx} value={despesa.id}>
                {despesa.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {maintenance !== '' && (
        <React.Fragment>
          {maintenance !== '' && Number(maintenance) > 2 && (
            <React.Fragment>
              {Number(maintenance) === 6 && (
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    label='Especifique uma breve descrição da manutenção'
                    fullWidth
                    required
                    onChange={(e) => setMaintenanceDescription(e.target.value)}
                  />
                </Grid>
              )}
            </React.Fragment>
          )}

          <Grid item xs={12}>
            <TextField
              label={'Valor total em €'}
              size='small'
              fullWidth
              required
              type='number'
              inputProps={{
                step: 0.01,
                min: 0,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={maintenanceAmount}
              onChange={(e) => setMaintenanceAmount(e.target.value)}
            />
          </Grid>

          {maintenance !== '' && Number(maintenance) === 1 && (
            <Grid item xs={12}>
              <TextField
                label={'Valor total em € para fração B)'}
                size='small'
                fullWidth
                required
                type='number'
                inputProps={{
                  step: 0.01,
                  min: 0,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={extraInfo && extraInfo['anualQuote']}
                onChange={(e) => {
                  setExtraInfo({ ...extraInfo, anualQuote: e.target.value })
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <DatePicker
              views={['year']}
              label='Ano Fiscal'
              defaultValue={null}
              format='YYYY'
              value={
                maintenanceFinancialYear !== ''
                  ? moment(maintenanceFinancialYear)
                  : null
              }
              onChange={(newValue) =>
                setMaintenanceFinancialYear(
                  newValue
                    ? moment(newValue).format('YYYY')
                    : moment().format('YYYY')
                )
              }
              slotProps={{
                textField: {
                  name: 'Ano Fiscal',
                  size: 'small',
                  fullWidth: true,
                  error: false,
                  variant: 'outlined',
                  required: true,
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              size='small'
              label='Notas adicionais (opcional)'
              fullWidth
              multiline
              rows={4}
              onChange={(e) => setMaintenanceNotes(e.target.value)}
            />
          </Grid>

          {maintenance !== '' && Number(maintenance) > 2 && (
            <Grid item xs={12}>
              <FormControl component='fieldset' variant='standard'>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={maintenanceToBeSplit}
                        onChange={(e) => {
                          setMaintenanceToBeSplit(e.target.checked)
                          if (e.target.checked) setMaintenanceToBeDivided(false)
                        }}
                      />
                    }
                    label={`Divisão por todas as frações (baseado na permilagem)`}
                  />
                </FormGroup>
              </FormControl>

              <FormControl component='fieldset' variant='standard'>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={maintenanceToBeDivided}
                        onChange={(e) => {
                          setMaintenanceToBeDivided(e.target.checked)
                          if (e.target.checked) setMaintenanceToBeSplit(false)
                        }}
                      />
                    }
                    label={`Divisão pelo condomínio pagante`}
                  />
                </FormGroup>
              </FormControl>

              {maintenanceToBeSplit && (
                <Alert severity='warning' sx={{ fontSize: 16 }}>
                  A selecionar esta opção, serão geradas automaticamente as
                  faturas para cada fração baseado na sua permilagem (inclui
                  fração A) e C) ).
                  <p>
                    <b>
                      Uma vez criada, este processo não poderá ser revertido.
                    </b>
                  </p>
                </Alert>
              )}
            </Grid>
          )}

          {([1, 2].includes(Number(maintenance)) || maintenanceToBeDivided) && (
            <Alert severity='warning' sx={{ fontSize: 16 }}>
              A adicionar esta manutenção, serão geradas automaticamente as
              faturas por todos os condóminos (exceto fração A) e C)).
              <p>
                <b>Uma vez criada, este processo não poderá ser revertido.</b>
              </p>
            </Alert>
          )}

          <Grid item xs={12} textAlign={'right'}>
            <Button
              variant='contained'
              color='primary'
              disabled={isSubmitting}
              onClick={handleCreateMaintenance}
            >
              Criar manutencao
            </Button>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}

export default AddMaintenance
