import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'

const useDownloadFile = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleDownloadFile = async (paymentId: number, fileName: string) => {
    setIsLoading(true)

    await axiosPrivate
      .get(`/api/download-file?pid=${paymentId}&fn=${fileName}`, {
        responseType: 'arraybuffer',
      })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.pdf`)
        document.body.appendChild(link)
        link.click()
      })
      .catch((err: any) => {
        showApiErrorMessage(err, 'Erro ao fazer download do ficheiro.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    isLoading,
    handleDownloadFile,
  }
}

export default useDownloadFile
