import React from 'react'

const FilesList = () => {
  return (
    <div>
      <h1>Ficheiros</h1>
      <br />
      <h2>Apenas será implementado na segunda fase do projecto</h2>
      <br />
      <h3>Exemplo a implementar no link abaixo</h3>
      <a
        target='_blank'
        href='https://mui.com/material-ui/react-tree-view/'
        rel='noreferrer'
      >
        React tree
      </a>
    </div>
  )
}

export default FilesList
