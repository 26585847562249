import {
  Typography,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import React, { useContext } from 'react'
import LoadingCircular from '../../../components/defaults/Loading'
import PageWithoutData from '../PageWithoutData'
import useFetchSingleIdea from '../../../hooks/ideas/useFetchSingleIdea'
import { useNavigate, useParams } from 'react-router-dom'
import { API_URLS } from '../../../utils/helper/helper'
import { IDEAS_STATUS, IdeaStatus } from '../../../utils/init/ideasInit'
import { AuthContext } from '../../../context/AuthProvider'
import useUpdateSingleIdea from '../../../hooks/ideas/useUpdateSingleIdea'

const IdeasEdit = () => {
  const { auth } = useContext(AuthContext)
  const navigate = useNavigate()
  const { ideaId } = useParams()
  const { ideaData, setIdeaData, isLoading } = useFetchSingleIdea(ideaId)
  const { handleUpdateSingeIdea, isUpdating } = useUpdateSingleIdea()

  const isUserSuperAdmin = auth?.roles.includes('ROLE_SUPER_ADMIN')
  const canUserEditIdea =
    isUserSuperAdmin ||
    (ideaData?.user?.id === auth?.userId &&
      ideaData?.status === IdeaStatus.Pending)

  console.log('ideaData', ideaData)

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingCircular />
      ) : ideaData === undefined ? (
        <PageWithoutData showButton={true} />
      ) : (
        <React.Fragment>
          <Typography
            variant='h2'
            sx={{
              fontWeight: 700,
              fontSize: '1.5rem',
              lineHeight: '1.375',
              mb: 2,
            }}
          >
            Editar idea nº {ideaData.id}
          </Typography>
          <Box>
            <TextField
              name='title'
              label='Titulo'
              defaultValue={ideaData.title}
              size='small'
              sx={{ width: '50%' }}
              onChange={(e) => {
                setIdeaData({
                  ...ideaData,
                  title: e.target.value,
                })
              }}
              disabled={!canUserEditIdea}
            />
          </Box>
          <Box mt={2}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id='status' size='small'>
                Estado
              </InputLabel>
              <Select
                name='status'
                labelId='status'
                label='Estado'
                size='small'
                sx={{ textAlign: 'left', width: '50%' }}
                value={ideaData.status}
                onChange={(e) => {
                  setIdeaData({
                    ...ideaData,
                    status: Number(e.target.value),
                  })
                }}
                disabled={!isUserSuperAdmin}
              >
                <MenuItem disabled value='-1'>
                  <em>Selecione o estado da ideia</em>
                </MenuItem>
                <MenuItem value='0'>Todos os tipos</MenuItem>
                {IDEAS_STATUS.map((idea) => (
                  <MenuItem key={idea.id} value={idea.id}>
                    {idea.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2}>
            <TextField
              name='description'
              label='Descrição'
              defaultValue={ideaData.description}
              size='small'
              sx={{ width: '50%' }}
              multiline
              minRows={4}
              onChange={(e) => {
                setIdeaData({
                  ...ideaData,
                  description: e.target.value,
                })
              }}
              disabled={!canUserEditIdea}
            />
          </Box>
          {canUserEditIdea && (
            <Box mt={2}>
              <Button
                variant='contained'
                color='primary'
                sx={{ width: 150 }}
                onClick={() => navigate(API_URLS.ideas)}
                disabled={isUpdating}
              >
                Voltar atrás
              </Button>
              <Button
                variant='contained'
                color='success'
                sx={{ ml: 2, width: 150 }}
                onClick={() => handleUpdateSingeIdea(ideaData)}
                disabled={isUpdating}
              >
                Guardar
              </Button>
            </Box>
          )}

          {!canUserEditIdea && (
            <Box mt={2}>
              <Button
                variant='contained'
                color='primary'
                sx={{ width: 200 }}
                onClick={() => navigate(API_URLS.ideas)}
              >
                Voltar para a lista
              </Button>
            </Box>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default IdeasEdit
