import { ResidentBlock } from '../../interfaces/residentblock'
import { convertDateToMonthYearFormat, numberFormatting } from './helper'

const getTenantsRentTotalToPay = (data: ResidentBlock[]) => {
  if (data.length > 0) {
    let total = data
      .map((tenant) => tenant.rentTotalToPay)
      .reduce((acc, rentTotalToPay) => acc + rentTotalToPay, 0)

    if (total < 0) {
      total = 0
    }

    return numberFormatting(total)
  }

  return '-'
}

const getTenantsRentPaidTill = (data: ResidentBlock[]) => {
  if (data.length > 0) {
    const activeBlock = data.filter((tenant) => tenant.isActive)
    if (activeBlock.length > 0) {
      return convertDateToMonthYearFormat(activeBlock[0].rentPaidTill)
    }
  }

  return '-'
}

const getTenantsLastPaymentDate = (data: ResidentBlock[]) => {
  if (data.length > 0) {
    const activeBlock = data.filter((tenant) => tenant.isActive)
    if (activeBlock.length > 0) {
      return convertDateToMonthYearFormat(activeBlock[0].rentLastPayment)
    }
  }

  return '-'
}

export {
  getTenantsRentTotalToPay,
  getTenantsRentPaidTill,
  getTenantsLastPaymentDate,
}
