import React, { useContext, useState } from 'react'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
// import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import {
  Assignment,
  ExpandLess,
  ExpandMore,
  TipsAndUpdates,
} from '@mui/icons-material'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined'
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined'
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import { AuthContext } from '../../../context/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type AdminMenuProps = {
  selectedMenu: string
  setSelectedMenu: (value: string) => void
}

const AdminMenu = ({ selectedMenu, setSelectedMenu }: AdminMenuProps) => {
  const { auth } = useContext(AuthContext)
  const navigate = useNavigate()
  const { t } = useTranslation(['home'])
  const [openManagement, setOpenManagement] = useState(true)
  const [openOthers, setOpenOthers] = useState(true)

  const handleClickExpandManagementMenu = () => {
    setOpenManagement(!openManagement)
  }

  const handleClickExpandOthersMenu = () => {
    setOpenOthers(!openOthers)
  }

  if (!auth?.roles.includes('ROLE_ADMIN')) {
    navigate(-1)
  }

  const handleLeftMenuClick = (menu: string) => {
    setSelectedMenu(menu)
    navigate(menu)
  }

  return (
    <React.Fragment>
      {/* Building Management */}
      <ListItemButton onClick={handleClickExpandManagementMenu} sx={{ mt: 3 }}>
        <ListItemText
          primary={t('admin.management.menuText')}
          sx={{
            fontSize: '20px',
            '& .MuiListItemText-primary': {
              fontSize: '14px !important',
              fontWeight: 700,
            },
          }}
        />
        {openManagement ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openManagement} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {/* <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'management'}
            onClick={() => handleLeftMenuClick('management')}
          >
            <ListItemIcon>
              <HomeOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.management.menuText')} />
          </ListItemButton> */}

          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'blocks'}
            onClick={() => handleLeftMenuClick('blocks')}
          >
            <ListItemIcon>
              <ApartmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.management.blocks.menuText')} />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'tenants'}
            onClick={() => handleLeftMenuClick('tenants')}
          >
            <ListItemIcon>
              <PeopleAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.management.residents.menuText')} />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'maintenance'}
            onClick={() => handleLeftMenuClick('maintenance')}
          >
            <ListItemIcon>
              <ReceiptLongOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('admin.management.maintenance.menuText')}
            />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'accounting'}
            onClick={() => handleLeftMenuClick('accounting')}
          >
            <ListItemIcon>
              <AccountBalanceWalletOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.management.accounting.menuText')} />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'reports'}
            onClick={() => handleLeftMenuClick('reports')}
          >
            <ListItemIcon>
              <Assignment />
            </ListItemIcon>
            <ListItemText primary={'Relatórios'} />
          </ListItemButton>
        </List>
      </Collapse>

      {/* Building Work */}
      <ListItemButton onClick={handleClickExpandOthersMenu} sx={{ mt: 3 }}>
        <ListItemText
          primary={t('admin.others.menuText')}
          sx={{
            fontSize: '20px',
            '& .MuiListItemText-primary': {
              fontSize: '14px !important',
              fontWeight: 700,
            },
          }}
        />
        {openOthers ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openOthers} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'works'}
            onClick={() => handleLeftMenuClick('works')}
          >
            <ListItemIcon>
              <EngineeringOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.others.works.menuText')} />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'files'}
            onClick={() => handleLeftMenuClick('files')}
          >
            <ListItemIcon>
              <FilePresentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.others.files.menuText')} />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'ideas'}
            onClick={() => handleLeftMenuClick('ideas')}
          >
            <ListItemIcon>
              <TipsAndUpdates />
            </ListItemIcon>
            <ListItemText primary={'Sugestões'} />
          </ListItemButton>
        </List>
      </Collapse>
    </React.Fragment>
  )
}

export default AdminMenu
