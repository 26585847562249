import { useContext, useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthProvider'

const useChangeUserPassword = () => {
  const axiosPrivate = useAxiosPrivate()
  const { auth, setAuth } = useContext(AuthContext)
  const navigate = useNavigate()
  const { showApiErrorMessage, showSystemMessage } = useToastNotification()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleChangePassword = async (password: string) => {
    setIsSubmitting(true)

    await axiosPrivate
      .patch(`/api/users/${auth?.userId}`, {
        password,
      })
      .then(() => {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        setAuth(undefined)

        navigate('/login')
        showSystemMessage('success', 'Password mudada com sucesso.')
      })
      .catch((error) => {
        showApiErrorMessage(
          error,
          'Erro ao mudar a password. Por favor, tente novamente.'
        )
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return {
    handleChangePassword,
    isSubmitting,
  }
}

export default useChangeUserPassword
