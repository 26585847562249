import { Box } from '@mui/material'
import React from 'react'
import { convertDateToMonthYearFormat } from '../../../../utils/helper/helper'
import { useTranslation } from 'react-i18next'
import { TBlockUpdateInfo } from '../types/types'
import Moment from 'react-moment'

const BlockUpdateInfo = ({ blockData }: TBlockUpdateInfo) => {
  const { t } = useTranslation(['home'])

  return (
    <Box className='box-shadow'>
      <div style={{ margin: '16px 8px' }}>
        <div>
          <div style={{ marginBottom: '10px' }}>
            <b>{t('admin.management.blocks.edit.lastUpdate')}</b>
          </div>
          <Moment locale='pt' fromNow ago>
            {blockData.updatedAt}
          </Moment>
        </div>
        <div style={{ marginTop: '30px' }}>
          <div style={{ marginBottom: '10px' }}>
            <b>{t('admin.management.blocks.edit.lastPayment')}</b>
          </div>
          <div>
            {blockData.residentBlocks[0].rentLastPayment
              ? convertDateToMonthYearFormat(
                  blockData.residentBlocks[0].rentLastPayment
                )
              : '-'}
          </div>
        </div>
      </div>
    </Box>
  )
}

export default BlockUpdateInfo
