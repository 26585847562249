import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import homePt from '../locales/pt/pt.json'
import homeEn from '../locales/en/en.json'

const resources = {
  en: {
    home: homeEn,
  },
  pt: {
    home: homePt,
  },
}

i18next.use(initReactI18next).init({
  resources,
  lng: 'pt',
  debug: false,
  fallbackLng: 'pt',
  saveMissing: true,
})

export default i18next
