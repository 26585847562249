import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'
import { Ideas } from '../../interfaces/ideas'

const useUpdateSingleIdea = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage, showSystemMessage } = useToastNotification()
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const handleUpdateSingeIdea = async (ideaData: Ideas) => {
    setIsUpdating(true)

    const { user, ...toBeUPdated } = ideaData

    await axiosPrivate
      .patch(`/api/ideas/${ideaData.id}`, {
        ...toBeUPdated,
      })
      .then(() => {
        showSystemMessage('success', 'Ideia atualizada com sucesso.')
      })
      .catch((error) => {
        showApiErrorMessage(
          error,
          'Erro ao atualizar a ideia. Por favor, tente novamente.'
        )
      })
      .finally(() => {
        setIsUpdating(false)
      })
  }

  return {
    handleUpdateSingeIdea,
    isUpdating,
  }
}

export default useUpdateSingleIdea
