import { ResidentBlock } from '../../interfaces/residentblock'
import { convertDateToMonthYearFormat } from './helper'

export const getResidentTotalRentToPay = (data: ResidentBlock[]) => {
  if (data.length > 0) {
    const total = data
      .map((tenant) => tenant.rentTotalToPay)
      .reduce((acc, rentTotalToPay) => acc + rentTotalToPay, 0)

    return total
  }

  return '-'
}

export const getResidentLastPayment = (data: ResidentBlock[]) => {
  if (data.length > 0) {
    const lastRent = data
      .map((tenant) => tenant.rentLastPayment)
      .reduce((last, rentLastPayment) => {
        if (last > rentLastPayment) {
          return last
        }

        return rentLastPayment
      }, '')

    return convertDateToMonthYearFormat(lastRent)
  }

  return '-'
}
