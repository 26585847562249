import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { TResidentData } from '../types/types'
import {
  getResidentLastPayment,
  getResidentTotalRentToPay,
} from '../../../../utils/helper/residentsHelper'
import { numberFormatting } from '../../../../utils/helper/helper'
import useAddResidentCreditOrDebit from '../../../../hooks/residents/useAddResidentCreditOrDebit'

const ResidentUpdateInfo = ({ residentData }: TResidentData) => {
  const { t } = useTranslation(['home'])
  const { handleAddCreditOrDebit, isSubmitting } = useAddResidentCreditOrDebit()
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [popupTitle, setPopupTitle] = useState<string>('')
  const [amount, setAmount] = useState<string>('')
  const totalRentToPay = getResidentTotalRentToPay(residentData.residentBlocks)

  const handleClosePopup = () => {
    setOpenPopup(false)
    setPopupTitle('')
    setAmount('')
  }

  return (
    <Box className='box-shadow'>
      <Grid container>
        <Grid item xs>
          <Box sx={{ margin: '16px 8px' }}>
            <Box mb={1}>
              <b>{t('admin.management.blocks.edit.lastUpdate')}</b>
            </Box>
            <Moment locale='pt' fromNow ago>
              {residentData.updatedAt}
            </Moment>
            <Box mt={3}>
              <Box mb={1}>
                <b>{t('admin.management.blocks.edit.lastPayment')}</b>
              </Box>
              <Box>{getResidentLastPayment(residentData.residentBlocks)}</Box>
            </Box>
          </Box>
        </Grid>
        <Divider orientation='vertical' flexItem />
        <Grid item xs ml={3}>
          <Box mt={2}>
            <Box mb={1}>
              <b>{t('default.balance')}</b>
            </Box>
            <Box
              sx={{
                color: `${
                  residentData.balance > 0
                    ? 'green'
                    : residentData.balance === 0
                    ? 'inherit'
                    : 'red'
                }`,
              }}
            >
              {numberFormatting(residentData.balance)}
            </Box>
          </Box>
          <Box mt={3}>
            <Box mb={1}>
              <b>{t('admin.management.residents.edit.rentTotalToPay')}</b>
            </Box>
            <Box
              sx={{
                color: `${
                  totalRentToPay !== '-' && totalRentToPay > 0
                    ? 'red'
                    : 'inherit'
                }`,
              }}
            >
              {totalRentToPay === '-'
                ? totalRentToPay
                : numberFormatting(totalRentToPay)}
            </Box>
          </Box>
          <Box mt={3}>
            <Box mb={1}>
              <b>{t('admin.management.residents.edit.workTotalToPay')}</b>
            </Box>
            <Box
            // sx={{
            //   color: `${
            //     residentData.residentAccount.rentTotalToPay > 0
            //       ? 'red'
            //       : 'inherit'
            //   }`,
            // }}
            >
              {/* {numberFormatting(residentData.residentAccount.workTotalToPay)} */}
              Não implementado ainda
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} mt={2}>
          <Button
            variant='contained'
            color='success'
            sx={{ width: '90%' }}
            onClick={() => {
              setOpenPopup(true)
              setPopupTitle('Crédito')
            }}
          >
            Adicionar Crédito
          </Button>
        </Grid>
        <Grid item xs={6} mt={2}>
          <Button
            variant='contained'
            color='error'
            sx={{ width: '90%' }}
            onClick={() => {
              setOpenPopup(true)
              setPopupTitle('Débito')
            }}
          >
            Adicionar débito
          </Button>
        </Grid>
      </Grid>

      <Dialog open={openPopup} onClose={handleClosePopup} fullWidth>
        <DialogTitle sx={{ fontWeight: 700 }}>
          Adicionar {popupTitle}
        </DialogTitle>

        <DialogContent>
          <TextField
            label={'Total'}
            size='small'
            fullWidth
            type='number'
            inputProps={{
              step: 0.01,
              min: 0,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mt: 1 }}
          />
        </DialogContent>

        <DialogActions sx={{ mr: 3, mb: 2 }}>
          <Button
            variant='outlined'
            size='small'
            color='error'
            onClick={handleClosePopup}
          >
            {t('default.cancel')}
          </Button>
          <Button
            variant='contained'
            size='small'
            color='warning'
            disabled={isSubmitting}
            onClick={() => {
              handleAddCreditOrDebit(
                residentData.id,
                parseFloat(amount),
                popupTitle
              )
            }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ResidentUpdateInfo
