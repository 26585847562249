import React, { ChangeEvent, useContext, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { AuthContext } from '../../../../context/AuthProvider'
import {
  addNewResidentApi,
  addNewResidentUserAccountApi,
} from '../../../../utils/rest'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import { TAddNewResidentComponent } from '../types/types'
import useToastNotification from '../../../../hooks/notifications/useToastNotification'

const initialFormData = {
  name: '',
  nif: '',
  phone: '',
  isActive: true,
}

const initialOnlineAccountFormData = {
  email: '',
  password: '',
  isActive: false,
  resident: '',
}

const AddNewResident = ({ setRefreshData }: TAddNewResidentComponent) => {
  const { auth } = useContext(AuthContext)
  const { t } = useTranslation(['home'])
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const [openPopup, setOpenPopup] = useState(false)
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const [onlineAccountFormData, setOnlineAccountFormData] = useState({
    ...initialOnlineAccountFormData,
    roles: ['ROLE_USER'],
    building: auth?.buildingId,
  })

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleOnlineAccInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setOnlineAccountFormData({
      ...onlineAccountFormData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleCancelButtonClick = () => {
    setOpenPopup(false)
  }

  const handleSaveTenant = async () => {
    if (disableSaveButton) {
      return
    }

    if (formData.name.length === 0) {
      showApiErrorMessage(t('errors.name'), 'erro')

      return
    }

    if (formData.nif.length === 0) {
      showApiErrorMessage(t('errors.nif'), 'erro')

      return
    }

    if (onlineAccountFormData.isActive) {
      if (onlineAccountFormData.email.length === 0) {
        showApiErrorMessage(t('errors.email'), 'erro')

        return
      }

      if (onlineAccountFormData.password.length === 0) {
        showApiErrorMessage(t('errors.password'), 'erro')

        return
      }
    }

    addTenantAccount()
  }

  const addTenantAccount = async () => {
    setDisableSaveButton(true)

    const apiResponse = await addNewResidentApi(axiosPrivate, formData)
    if (apiResponse.success) {
      await addTenantOnlineAccount(apiResponse.data)

      setRefreshData(true)
      handleCancelButtonClick()
    } else {
      showApiErrorMessage(apiResponse.data, 'erro')
    }

    setDisableSaveButton(false)

    //Reset formData
    setFormData(initialFormData)
    setOnlineAccountFormData({
      ...onlineAccountFormData,
      ...initialOnlineAccountFormData,
    })
  }

  const addTenantOnlineAccount = async (tenantId: string) => {
    if (!onlineAccountFormData.isActive) {
      return
    }

    let onlinePayload = { ...onlineAccountFormData }
    onlinePayload.resident = tenantId

    const apiResponse = await addNewResidentUserAccountApi(
      axiosPrivate,
      onlinePayload
    )
    if (!apiResponse.success) {
      showApiErrorMessage(apiResponse.data, 'erro')
    }
  }

  return (
    <React.Fragment>
      <Box ml={'auto'}>
        <Button
          color='warning'
          variant='contained'
          onClick={() => setOpenPopup(true)}
        >
          {t('admin.management.residents.list.newTenant')}
        </Button>
      </Box>

      <Dialog
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        fullWidth
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 800,
          },
        }}
      >
        <DialogTitle sx={{ fontWeight: 700 }}>
          {t('admin.management.residents.edit.createAccount')}
        </DialogTitle>

        <DialogContent>
          <Box component='form' autoComplete='off' mt={1} display={'flex'}>
            <Box width={'50%'}>
              <TextField
                name='name'
                label={t('default.fullName')}
                defaultValue={formData.name}
                size='small'
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
                required
              />

              <TextField
                name='nif'
                label='NIF'
                defaultValue={formData.nif}
                size='small'
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
                required
              />

              <TextField
                name='phone'
                label={t('default.phone')}
                defaultValue={formData.phone}
                size='small'
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name='isActive'
                      checked={formData.isActive}
                      onChange={handleInputChange}
                    />
                  }
                  label={
                    formData.isActive
                      ? t('default.active')
                      : t('default.disabled')
                  }
                />
              </FormGroup>
            </Box>
            <Divider orientation='vertical' flexItem sx={{ ml: 3, mr: 3 }} />

            <Box width={'50%'}>
              <Box mb={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        name='isActive'
                        checked={onlineAccountFormData.isActive}
                        onChange={handleOnlineAccInputChange}
                      />
                    }
                    labelPlacement='start'
                    label={t(
                      'admin.management.residents.list.addResidentUserAccount'
                    )}
                    sx={{ justifyContent: 'left', ml: 0 }}
                  />
                </FormGroup>
                <Box
                  mt={2.3}
                  display={onlineAccountFormData.isActive ? 'inherit' : 'none'}
                >
                  <TextField
                    name='email'
                    label='Email'
                    defaultValue={onlineAccountFormData.email}
                    size='small'
                    onChange={handleOnlineAccInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                    required
                  />

                  <FormControl variant='outlined' size='small' fullWidth>
                    <InputLabel htmlFor='password'>Password *</InputLabel>
                    <OutlinedInput
                      id='password'
                      name='password'
                      type={
                        showPassword ||
                        onlineAccountFormData.password.length === 0
                          ? 'text'
                          : 'password'
                      }
                      onChange={handleOnlineAccInputChange}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword(!showPassword)}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label='Password *'
                    />
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions sx={{ mr: 3, mb: 2 }}>
          <Button
            variant='outlined'
            size='small'
            color='error'
            onClick={handleCancelButtonClick}
          >
            {t('default.cancel')}
          </Button>
          <Button
            variant='contained'
            size='small'
            color='warning'
            disabled={disableSaveButton}
            onClick={handleSaveTenant}
          >
            {t('default.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default AddNewResident
