import * as React from 'react'
// import Table from '@mui/material/Table'
// import TableBody from '@mui/material/TableBody'
// import TableCell from '@mui/material/TableCell'
// import TableContainer from '@mui/material/TableContainer'
// import TableHead from '@mui/material/TableHead'
// import TableRow from '@mui/material/TableRow'
// import Paper from '@mui/material/Paper'
import { Typography } from '@mui/material'

const AuditLogsList = () => {
  return (
    <React.Fragment>
      <Typography
        variant='h2'
        sx={{
          fontWeight: 700,
          fontSize: '1.5rem',
          lineHeight: '1.375',
          mb: 2,
        }}
      >
        Audit Logs list
      </Typography>

      <div style={{ marginTop: 50 }}>
        Example below
        <img
          src='https://raw.githubusercontent.com/aspnetzero/documents/master/docs/en/images/audit-logs-core-4.png'
          alt=''
        />
      </div>

      <div style={{ marginTop: 50 }}>
        Another Example below
        <img
          src='https://s3.amazonaws.com/cdn.freshdesk.com/data/helpdesk/attachments/production/50001581498/original/KLH3HN15dmEgOhTwLnq3Bah9K69xPoTpiw.png?1597345078'
          alt=''
          style={{ maxWidth: '100%' }}
        />
      </div>
    </React.Fragment>
  )
}

export default AuditLogsList
