import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import AllPaymentsList from '../../../components/admin/accounting/AllPaymentsList'
import ToReceiveBoxes from '../../../components/admin/accounting/ToReceiveBoxes'
import BlocksPaymentQueueList from '../../../components/admin/accounting/BlocksPaymentQueueList'
import WorksPaymentQueueList from '../../../components/admin/accounting/WorksPaymentQueueList'
import MaintenanceList from '../../../components/admin/accounting/MaintenanceAccountingList'
import { useNavigate } from 'react-router-dom'
import { API_URLS } from '../../../utils/helper/helper'

const AccountingList = () => {
  const { t } = useTranslation(['home'])
  const navigate = useNavigate()
  const [tabSelected, setTabSelected] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue)
  }

  return (
    <React.Fragment>
      <Box display={'flex'}>
        <Typography variant='h2' className='content-title'>
          {t('admin.management.accounting.menuText')}
        </Typography>

        <Box ml={'auto'}>
          <Button
            color='warning'
            variant='contained'
            onClick={() => navigate(`${API_URLS.accounting}/new`)}
          >
            {t('admin.management.blocks.edit.newPayment')}
          </Button>
        </Box>
      </Box>

      <ToReceiveBoxes />

      <Box
        sx={{ width: '450px', margin: '50px auto', p: 0 }}
        className='box-shadow'
      >
        <Tabs value={tabSelected} onChange={handleTabChange} centered>
          <Tab label='Sumário' />
          <Tab label={t('default.fractions')} />
          <Tab label={t('default.works')} />
          <Tab label={t('admin.management.maintenance.menuText')} />
        </Tabs>
      </Box>

      {tabSelected === 0 && <AllPaymentsList />}
      {tabSelected === 1 && <BlocksPaymentQueueList />}
      {tabSelected === 2 && <WorksPaymentQueueList />}
      {tabSelected === 3 && <MaintenanceList />}
    </React.Fragment>
  )
}

export default AccountingList
