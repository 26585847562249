import { Chip } from '@mui/material'
import {
  TCustomChipDetails,
  TCustomStatusChipListProps,
} from '../../utils/types/types'

const CustomStatusChip = ({
  selectedStatus,
  statusList,
}: TCustomStatusChipListProps) => {
  const statusChipDetails: TCustomChipDetails[] | [] = statusList.filter(
    (stat) => stat.id === selectedStatus
  )

  if (statusChipDetails.length > 0) {
    return (
      <Chip
        label={statusChipDetails[0].label}
        color={statusChipDetails[0].color}
        size='small'
        variant={statusChipDetails[0].variant}
        sx={{ ...statusChipDetails[0].options, fontWeight: '700' }}
      />
    )
  }

  return <Chip label='ERROR' color='error' size='small' variant='filled' />
}

export default CustomStatusChip
