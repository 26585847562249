import React from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { numberFormatting } from '../../../../utils/helper/helper'
import { green } from '@mui/material/colors'
import { AttachFileOutlined } from '@mui/icons-material'
import { LatePaymentResponseData } from '../../../../hooks/payments/useAddUserLatePayment'

type LatePaymentConfirmationProps = {
  paymentData: LatePaymentResponseData
  residentBalance: number
  useResidentBalance: boolean
  forceDownloadFile: (arg0: number, arg1: string) => void
}

const LatePaymentConfirmation = ({
  paymentData,
  residentBalance,
  useResidentBalance,
  forceDownloadFile,
}: LatePaymentConfirmationProps) => {
  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'right' }}>
        <Button
          variant='contained'
          sx={{ mt: 1 }}
          onClick={() => {
            window.location.reload()
          }}
        >
          Adicionar novo pagamento
        </Button>
      </Box>

      <Box mt={2}>
        {paymentData.paid.length === 0 ? (
          <Alert severity='error'>
            <AlertTitle>Nenhum pagamento efetuado</AlertTitle>
            Ocorreu um erro ao processar o(s) pagamento(s). Por favor, tente
            novamente.
          </Alert>
        ) : (
          <Alert severity='success'>
            <AlertTitle>
              {paymentData.totalQuotesPaid} pagamento(s) efetuado(s) com sucesso
            </AlertTitle>
            {useResidentBalance && (
              <p>
                Foi reduzido{' '}
                {numberFormatting(
                  residentBalance - paymentData.newResidentBalance
                )}{' '}
                do saldo do condómino que é agora de{' '}
                {numberFormatting(paymentData.newResidentBalance)}.
              </p>
            )}
          </Alert>
        )}
      </Box>

      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='h4' mb={1}>
              Pagamentos efetuados
            </Typography>
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Pago</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData.paid.map((blockPayments, idx) => {
                const blockPaymentsList = blockPayments.map((payment) => {
                  return (
                    <TableRow key={payment.id}>
                      <TableCell component='th' scope='row'>
                        {payment.description}
                      </TableCell>

                      <TableCell component='th' scope='row'>
                        {numberFormatting(payment.amount)}
                      </TableCell>
                    </TableRow>
                  )
                })

                return (
                  <React.Fragment key={idx}>
                    <TableRow sx={{ backgroundColor: '#ededf3' }}>
                      <TableCell
                        component='th'
                        scope='row'
                        sx={{
                          fontWeight: 700,
                          padding: '8px 16px',
                          fontSize: 15,
                        }}
                      >
                        Bloco {blockPayments[0].block}
                      </TableCell>
                      <TableCell
                        component='th'
                        scope='row'
                        sx={{
                          fontWeight: 700,
                          padding: '8px 16px',
                          fontSize: 15,
                        }}
                      >
                        <AttachFileOutlined
                          color='primary'
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            forceDownloadFile(
                              paymentData.paid[idx][0].id,
                              paymentData.files[idx]
                            )
                          }
                        />
                      </TableCell>
                    </TableRow>
                    {blockPaymentsList}
                  </React.Fragment>
                )
              })}
              <TableRow sx={{ borderBottom: 'none' }}>
                <TableCell
                  component='th'
                  scope='row'
                  colSpan={2}
                  sx={{ borderBottom: 'none' }}
                >
                  <Typography
                    variant='h4'
                    mb={1}
                    sx={{ textDecoration: 'underline' }}
                    fontWeight={700}
                  >
                    <span style={{ color: green[500] }}>TOTAL PAGO:</span>
                    &nbsp;{numberFormatting(paymentData.totalAmountPaid)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default LatePaymentConfirmation
