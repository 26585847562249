import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'
import { useNavigate } from 'react-router-dom'

type HandleAddMaintenancesProps = {
  type: string
  amount: string
  notes: string
  financialYear: string
  description: string
  splitable: boolean
  extraInfo: string[]
  maintenanceToBeDivided: boolean
}

const useAddMaintenance = () => {
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { showApiErrorMessage, showSystemMessage } = useToastNotification()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleAddMaintenances = async ({
    type,
    amount,
    notes,
    financialYear,
    description,
    splitable,
    extraInfo,
    maintenanceToBeDivided,
  }: HandleAddMaintenancesProps) => {
    setIsSubmitting(true)

    await axiosPrivate
      .post(`/api/maintenances`, {
        amount,
        notes,
        type,
        financialYear,
        description,
        splitable,
        extraInfo,
        maintenanceToBeDivided,
      })
      .then(() => {
        navigate('/admin/maintenance')
        showSystemMessage('success', 'Manutenção adicionada com sucesso.')
      })
      .catch((error) => {
        showApiErrorMessage(
          error,
          'Erro ao adicionar nova manutenções. Por favor, tente novamente.'
        )
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return {
    handleAddMaintenances,
    isSubmitting,
  }
}

export default useAddMaintenance
