import React from 'react'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'

export default function ActiveIcon({ isActive }: { isActive: boolean }) {
  return isActive ? (
    <CheckCircleOutlinedIcon sx={{ color: 'green' }} />
  ) : (
    <HighlightOffOutlinedIcon sx={{ color: 'red' }} />
  )
}
