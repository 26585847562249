import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'
import { FinancialYearReport } from '../../pages/types/reportTypes'

const useReportFinancialYear = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const [financialYearData, setFinancialYearData] = useState<
    FinancialYearReport | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleFetchFinancialYearReport = async (financialYear: string) => {
    setIsLoading(true)

    await axiosPrivate
      .get(`/api/reports/financial-year?year=${financialYear}`)
      .then((response) => {
        setFinancialYearData(response.data)
      })
      .catch((error) => {
        showApiErrorMessage(
          error,
          'Erro ao gerar o relatório fiscal anual. Por favor, tente novamente.'
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleDownloadFinancialYearReport = async (financialYear: string) => {
    setIsLoading(true)

    await axiosPrivate
      .get(`/api/reports/financial-year-pdf?year=${financialYear}`, {
        responseType: 'arraybuffer',
      })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `Relatorio-Contas-Ano-de-${financialYear}.pdf`
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch((err: any) => {
        showApiErrorMessage(err, 'Erro ao fazer download do relatório.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    financialYearData,
    isLoading,
    handleFetchFinancialYearReport,
    handleDownloadFinancialYearReport,
  }
}

export default useReportFinancialYear
