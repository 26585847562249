import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import useFetchIdeas from '../../../hooks/ideas/useFetchIdeas'
import { Ideas } from '../../../interfaces/ideas'
import AddNewIdea from '../../../components/admin/ideas/AddNewIdea'
import moment from 'moment'
import CustomStatusChip from '../../../components/defaults/CustomStatusChip'
import { IDEAS_STATUS, IdeaStatus } from '../../../utils/init/ideasInit'
import { AuthContext } from '../../../context/AuthProvider'
import { EditNote, EventAvailable, EventBusy } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { API_URLS } from '../../../utils/helper/helper'
import useUpdateIdeaStatus from '../../../hooks/ideas/useUpdateIdeaStatus'

const IdeasPage = () => {
  const { auth } = useContext(AuthContext)
  const navigate = useNavigate()
  const { isLoading, ideas, handleFetchMaintenances, totalIdeas } =
    useFetchIdeas()
  const { handleChangeIdeaStatus } = useUpdateIdeaStatus()
  const [ideaStatus, setIdeaStatus] = useState<number>(0)
  const [page, setPage] = useState<number>(0)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
    handleFetchMaintenances(newPage)
  }

  return (
    <React.Fragment>
      <Box display={'flex'}>
        <Typography variant='h2' className='content-title'>
          Ideais / Sugestões
        </Typography>

        <Box ml={'auto'}>
          <AddNewIdea
            handleFetchMaintenances={() => handleFetchMaintenances(page)}
          />
        </Box>
      </Box>

      <Box textAlign={'right'} mb={1} mt={1}>
        <FormControl sx={{ mr: 1, minWidth: 250 }}>
          <InputLabel id='ideaStatus' size='small'>
            Estado
          </InputLabel>
          <Select
            name='ideaStatus'
            labelId='ideaStatus'
            label='Estado'
            size='small'
            sx={{ textAlign: 'left' }}
            value={ideaStatus}
            onChange={(e) => {
              setIdeaStatus(Number(e.target.value))
              setPage(0)
              handleFetchMaintenances(0, Number(e.target.value))
            }}
          >
            <MenuItem disabled value='-1'>
              <em>Selecione o estado da ideia</em>
            </MenuItem>
            <MenuItem value='0'>Todos os tipos</MenuItem>
            {IDEAS_STATUS.map((idea) => (
              <MenuItem key={idea.id} value={idea.id}>
                {idea.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer
        component={Paper}
        sx={{ opacity: `${isLoading ? 0.2 : 1}` }}
      >
        <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Título</TableCell>
              <TableCell>Descrição</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Adicionado por</TableCell>
              <TableCell>Adicionado em</TableCell>
              <TableCell>Atualizado em</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ideas.length === 0 && (
              <TableRow>
                <TableCell colSpan={8}>
                  Nenhuma idea / sugestão encontrada
                </TableCell>
              </TableRow>
            )}
            {ideas.length > 0 &&
              ideas.map((idea: Ideas) => {
                return (
                  <TableRow key={idea.id}>
                    <TableCell>{idea.id}</TableCell>
                    <TableCell>{idea.title}</TableCell>
                    <TableCell>{idea.description}</TableCell>
                    <TableCell>
                      <CustomStatusChip
                        selectedStatus={idea.status}
                        statusList={IDEAS_STATUS}
                      />
                    </TableCell>
                    <TableCell>{idea.user.email}</TableCell>
                    <TableCell>
                      {moment(idea.createdAt).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      {moment(idea.updatedAt).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell>
                      <EditNote
                        color={'primary'}
                        cursor={'pointer'}
                        onClick={() => navigate(`${API_URLS.ideas}/${idea.id}`)}
                      />

                      {idea.status === IdeaStatus.Pending &&
                        auth?.roles.includes('ROLE_SUPER_ADMIN') && (
                          <React.Fragment>
                            <EventAvailable
                              color='success'
                              cursor={'pointer'}
                              onClick={() => {
                                handleChangeIdeaStatus(
                                  idea.id,
                                  IdeaStatus.Accepted,
                                  () => handleFetchMaintenances(page)
                                )
                              }}
                            />
                            <EventBusy
                              color='error'
                              cursor={'pointer'}
                              onClick={() => {
                                handleChangeIdeaStatus(
                                  idea.id,
                                  IdeaStatus.Rejected,
                                  () => handleFetchMaintenances(page)
                                )
                              }}
                            />
                          </React.Fragment>
                        )}
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
          <TableFooter>
            <TableRow sx={{ width: '100%' }}>
              <TablePagination
                count={totalIdeas}
                rowsPerPage={10}
                page={page}
                onPageChange={handleChangePage}
                labelRowsPerPage=''
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default IdeasPage
