import React from 'react'
import { AutoPaymentResponseData } from '../../../../hooks/payments/useAddUserAutoPayment'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { numberFormatting } from '../../../../utils/helper/helper'
import { green, red } from '@mui/material/colors'
import { AttachFileOutlined } from '@mui/icons-material'

type AutoPaymentConfirmationProps = {
  paymentData: AutoPaymentResponseData
  amount: string
  residentBalance: number
  useResidentBalance: boolean
  forceDownloadFile: () => void
}

const AutoPaymentConfirmation = ({
  paymentData,
  amount,
  residentBalance,
  useResidentBalance,
  forceDownloadFile,
}: AutoPaymentConfirmationProps) => {
  const parsedAmount = parseFloat(amount)
  let totalAmountToPay = 0
  let totalAmountPaid = 0

  const amountReceivedMsg = () => {
    const parsedResidentBalance = useResidentBalance ? residentBalance : 0
    let msg = `${numberFormatting(parsedAmount + parsedResidentBalance)}`

    if (parsedResidentBalance > 0) {
      msg += `(${numberFormatting(parsedAmount)} recebido + ${numberFormatting(
        parsedResidentBalance
      )} de saldo)`
    }

    return msg
  }

  const residentBalanceMsg = () => {
    let msg = ''

    if (paymentData.newResidentBalance < residentBalance) {
      msg += `Foi reduzido ${numberFormatting(
        residentBalance - paymentData.newResidentBalance
      )} do saldo do condómino que é agora de ${numberFormatting(
        paymentData.newResidentBalance
      )}.`
    } else if (paymentData.newResidentBalance > residentBalance) {
      msg += `Do valor recebido / pago soubrou ${numberFormatting(
        paymentData.newResidentBalance - residentBalance
      )} que foi adicionado automaticamento ao saldo do condómino que é agora de ${numberFormatting(
        paymentData.newResidentBalance
      )}.`
    }

    return msg
  }

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'right' }}>
        <Button
          variant='contained'
          sx={{ mb: 2 }}
          onClick={() => {
            window.location.reload()
          }}
        >
          Adicionar novo pagamento
        </Button>
      </Box>

      {paymentData.paid.length === 0 ? (
        <Alert severity='error'>
          <AlertTitle>
            Nenhum pagamento efetuado, fundos insuficientes
          </AlertTitle>
          O montante total recebido de {amountReceivedMsg()} não foi suficiente
          para pagar qualquer quota. Verifique a lista de quotas a pagar em
          baixo.
        </Alert>
      ) : paymentData.toPay.length === 0 ? (
        <Alert severity='success'>
          <AlertTitle>
            {paymentData.paid.length} pagamento(s) efetuado(s)
          </AlertTitle>
          <p>
            O montante total recebido de {amountReceivedMsg()} foi suficiente
            para pagar {paymentData.paid.length} quota(s).
          </p>
          <p>{residentBalanceMsg()}</p>
        </Alert>
      ) : (
        <Alert severity='warning'>
          <AlertTitle>
            {paymentData.paid.length} pagamento(s) efetuado(s)
          </AlertTitle>
          <p>
            O montante total recebido de {amountReceivedMsg()} foi suficiente
            para pagar {paymentData.paid.length} quota(s), no entanto ainda
            existem alguns pagamentos em atraso.
          </p>
          <p>{residentBalanceMsg()}</p>
        </Alert>
      )}

      <Grid container spacing={2} mt={1}>
        <Grid item xs={6}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='h4' mb={1}>
              Pagamentos efetuados
            </Typography>
            <AttachFileOutlined
              color='primary'
              sx={{ cursor: 'pointer' }}
              onClick={forceDownloadFile}
            />
          </Box>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Pago</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData.paid.map((payment) => {
                totalAmountPaid += payment.amount
                return (
                  <TableRow key={payment.id}>
                    <TableCell component='th' scope='row'>
                      {payment.description}
                    </TableCell>

                    <TableCell component='th' scope='row'>
                      {numberFormatting(payment.amount)}
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow sx={{ borderBottom: 'none' }}>
                <TableCell
                  component='th'
                  scope='row'
                  colSpan={2}
                  sx={{ borderBottom: 'none' }}
                >
                  <Typography
                    variant='h4'
                    mb={1}
                    sx={{ textDecoration: 'underline' }}
                    fontWeight={700}
                  >
                    <span style={{ color: green[500] }}>TOTAL PAGO:</span>
                    &nbsp;{numberFormatting(totalAmountPaid)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h4' mb={1}>
            Pagamentos em atraso
          </Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>A pagar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData.toPay.map((payment) => {
                totalAmountToPay += payment.amount
                return (
                  <TableRow key={payment.id}>
                    <TableCell component='th' scope='row'>
                      {payment.description}
                    </TableCell>

                    <TableCell component='th' scope='row'>
                      {numberFormatting(payment.amount)}
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell
                  component='th'
                  scope='row'
                  colSpan={2}
                  sx={{ borderBottom: 'none' }}
                >
                  <Typography
                    variant='h4'
                    mb={1}
                    sx={{ textDecoration: 'underline' }}
                    fontWeight={700}
                  >
                    <span style={{ color: red[500] }}>TOTAL A PAGAR:</span>{' '}
                    &nbsp;
                    {numberFormatting(totalAmountToPay)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default AutoPaymentConfirmation
