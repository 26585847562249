import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useToastNotification from '../notifications/useToastNotification'

type HandleAddIdeaProps = {
  title: string
  description: string
  successFn: () => void
}

const useAddIdea = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage, showSystemMessage } = useToastNotification()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleAddIdea = async ({
    title,
    description,
    successFn,
  }: HandleAddIdeaProps) => {
    setIsSubmitting(true)

    await axiosPrivate
      .post(`/api/ideas`, {
        title,
        description,
      })
      .then(() => {
        successFn()
        showSystemMessage('success', 'Ideia / Sugestão adicionada com sucesso.')
      })
      .catch((error) => {
        showApiErrorMessage(
          error,
          'Erro ao adicionar nova ideia / sugestão. Por favor, tente novamente.'
        )
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return {
    handleAddIdea,
    isSubmitting,
  }
}

export default useAddIdea
