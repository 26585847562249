import { useNavigate, useParams } from 'react-router-dom'
import image from '../../../images/userdetails.png'

const UsersDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  return (
    <div>
      <h3>ID: {id}</h3>
      <br />
      <button onClick={() => navigate(-1)}>Go back</button>

      <div>
        <img src={image} />
      </div>
    </div>
  )
}

export default UsersDetail
