import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import useAxiosPrivate from '../../../api/hooks/useAxiosPrivate'
import { Resident } from '../../../interfaces/resident'
import { getSingleResidentApiData } from '../../../utils/rest'
import LoadingCircular from '../../../components/defaults/Loading'
import PageWithoutData from '../PageWithoutData'
import PageNotFound from '../PageNotFound'
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import ResidentInfo from '../../../components/admin/residents/edit/ResidentInfo'
import ResidentUpdateInfo from '../../../components/admin/residents/edit/ResidentUpdateInfo'
import ResidentPaymentsList from '../../../components/admin/residents/edit/ResidentPaymentsList'
import ResidentOldBlocks from '../../../components/admin/residents/edit/ResidentOldBlocks'

const TenantDetails = () => {
  const { id } = useParams()
  const { t } = useTranslation(['home'])
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const [errorFetching, setErrorFetching] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tabSelected, setTabSelected] = useState(0)
  const [refreshData, setRefreshData] = useState(false)
  const [residentData, setResidentData] = useState<Resident | undefined>(
    undefined
  )

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabSelected(newValue)
  }

  useEffect(() => {
    if (id === undefined) {
      navigate(-1)
    }

    const getResidentById = async () => {
      setLoading(true)

      const apiResponse = await getSingleResidentApiData(axiosPrivate, id)
      if (!apiResponse.success) {
        setErrorFetching(true)
      } else {
        setResidentData(apiResponse.data)
      }

      setLoading(false)
    }

    getResidentById()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshData])

  return (
    <React.Fragment>
      {loading ? (
        <LoadingCircular />
      ) : errorFetching ? (
        <PageNotFound />
      ) : residentData === undefined ? (
        <PageWithoutData showButton={true} />
      ) : (
        <React.Fragment>
          <Typography
            variant='h2'
            sx={{
              fontWeight: 700,
              fontSize: '1.5rem',
              lineHeight: '1.375',
              mb: 2,
            }}
          >
            {t('admin.management.residents.edit.title')} {residentData.name}
          </Typography>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <ResidentInfo
                  residentData={residentData}
                  refreshData={refreshData}
                  setRefreshData={setRefreshData}
                />
              </Grid>
              <Grid item xs={5}>
                <ResidentUpdateInfo residentData={residentData} />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{ width: '280px', margin: '50px auto', p: 0 }}
            className='box-shadow'
          >
            <Tabs value={tabSelected} onChange={handleTabChange} centered>
              <Tab label={t('default.payments')} />
              <Tab label={t('default.fractions')} />
            </Tabs>
          </Box>

          {tabSelected === 0 && (
            <ResidentPaymentsList resident={residentData} />
          )}
          {tabSelected === 1 && (
            <ResidentOldBlocks residentId={residentData.id} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
export default TenantDetails
