import { Button, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import useToastNotification from '../../hooks/notifications/useToastNotification'
import useChangeUserPassword from '../../hooks/users/useChangeUserPassword'

const ChangePassword = () => {
  const { showSystemMessage } = useToastNotification()
  const { handleChangePassword, isSubmitting } = useChangeUserPassword()
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')

  const handleChangePasswordClick = () => {
    if (password !== passwordConfirmation) {
      showSystemMessage('error', 'As passwords não coincidem.')
      return
    }

    handleChangePassword(password)
  }
  return (
    <React.Fragment>
      <Typography variant='h2' className='content-title'>
        Mudar a password
      </Typography>
      <TextField
        type='password'
        label='Insira a nova password'
        value={password}
        size='small'
        fullWidth
        sx={{ mb: 2 }}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        type='password'
        label='Confirme a nova password'
        size='small'
        value={passwordConfirmation}
        fullWidth
        sx={{ mb: 2 }}
        onChange={(e) => setPasswordConfirmation(e.target.value)}
      />
      <Button
        variant='contained'
        disabled={isSubmitting}
        onClick={handleChangePasswordClick}
      >
        Mudar password
      </Button>
    </React.Fragment>
  )
}

export default ChangePassword
