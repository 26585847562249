import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/AuthProvider'
import '../src/i18n/i18n'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/pt'
import { SnackbarProvider } from 'notistack'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <AuthProvider>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='pt'>
      <BrowserRouter>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ marginRight: -1 }}
        >
          <App />
        </SnackbarProvider>
      </BrowserRouter>
    </LocalizationProvider>
  </AuthProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
