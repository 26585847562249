import React, { useContext, useState } from 'react'
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import { AuthContext } from '../../../context/AuthProvider'
import SuperAdminMenu from './SuperAdminMenu'
import { useNavigate } from 'react-router-dom'
import AdminMenu from './AdminMenu'
import { useTranslation } from 'react-i18next'

const LeftMenu = () => {
  const { auth } = useContext(AuthContext)
  const { t } = useTranslation(['home'])
  const navigate = useNavigate()
  const [selectedMenu, setSelectedMenu] = useState<string>('dashboard')

  return (
    <Box id='left-nav-bar' width={'250px'}>
      <Box
        sx={{
          padding: '30px 8px 30px 8px',
        }}
      >
        <List
          sx={{
            '& .MuiListItemText-primary': {
              fontSize: '15px',
            },
          }}
        >
          {/* Dashboard */}
          <ListItemButton
            selected={selectedMenu === 'dashboard'}
            onClick={() => {
              setSelectedMenu('dashboard')
              navigate('/admin')
            }}
          >
            <ListItemIcon>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.dashboard.menuText')} />
          </ListItemButton>

          {auth?.roles.includes('ROLE_ADMIN') && (
            <AdminMenu
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
            />
          )}

          {/* Super Admin  */}
          {auth?.roles.includes('ROLE_SUPER_ADMIN') && (
            <SuperAdminMenu
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
            />
          )}
        </List>
      </Box>
    </Box>
  )
}

export default LeftMenu
