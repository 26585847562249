import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TPageWithoutData } from '../types/types'

const PageWithoutData = ({ showButton }: TPageWithoutData) => {
  const { t } = useTranslation(['home'])
  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'center' }}>
        <Typography
          variant='h2'
          sx={{
            fontWeight: 700,
            fontSize: '1.5rem',
            lineHeight: '1.375',
            mb: 3,
          }}
        >
          {t('admin.pageWithoutData.title')}
        </Typography>
        {showButton && (
          <Button
            variant='contained'
            color='warning'
            onClick={() => navigate(-1)}
            sx={{ mt: 3 }}
          >
            {t('admin.pageWithoutData.buttonText')}
          </Button>
        )}
      </Box>
    </React.Fragment>
  )
}

export default PageWithoutData
