import axios from 'axios'

const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/ld+json',
  },
})

export { axiosBase, axiosPrivate }
