import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from '@mui/material'
import { TResidentInfo } from '../types/types'
import { API_URLS } from '../../../../utils/helper/helper'
import ResidentOnlineAccount from './ResidentOnlineAccount'
import { editResidentApi } from '../../../../utils/rest'
import { useNavigate } from 'react-router-dom'
import useToastNotification from '../../../../hooks/notifications/useToastNotification'

const ResidentInfo = ({
  residentData,
  setRefreshData,
  refreshData,
}: TResidentInfo) => {
  const { t } = useTranslation(['home'])
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const hasActiveBlock = residentData.residentBlocks.some((el) => el.isActive)
  const [auxResidentData, setAuxResidentData] = useState({
    name: residentData.name,
    nif: residentData.nif,
    phone: residentData.phone,
    isActive: residentData.isActive,
  })

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    if (name === 'isActive' && hasActiveBlock) {
      showApiErrorMessage(t('errors.deactivateTenant'), 'erro')

      return
    }

    setAuxResidentData({
      ...auxResidentData,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const handleSaveResidentChanges = async () => {
    if (auxResidentData.name.length === 0) {
      showApiErrorMessage(t('errors.name'), 'erro')

      return
    }

    if (auxResidentData.nif.length === 0) {
      showApiErrorMessage(t('errors.nif'), 'erro')

      return
    }

    const apiResponse = await editResidentApi(
      axiosPrivate,
      auxResidentData,
      residentData.id
    )
    if (apiResponse.success) {
      navigate(API_URLS.tenants)
    } else {
      showApiErrorMessage(apiResponse, 'erro')
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          '& .MuiTextField-root': { m: '16px 8px' },
        }}
        className='box-shadow'
      >
        <Box mr={2}>
          <TextField
            name='name'
            label={t('default.fullName')}
            defaultValue={auxResidentData.name}
            size='small'
            fullWidth
            onChange={handleInputChange}
            required
          />
        </Box>
        <Box style={{ display: 'flex' }}>
          <TextField
            name='nif'
            label='NIF'
            defaultValue={auxResidentData.nif}
            size='small'
            sx={{ width: '50%' }}
            onChange={handleInputChange}
            required
          />
          <TextField
            name='phone'
            label={t('default.phone')}
            defaultValue={auxResidentData.phone}
            size='small'
            sx={{ width: '50%' }}
            onChange={handleInputChange}
          />
        </Box>
        <Box mb={2} ml={1}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  name='isActive'
                  checked={auxResidentData.isActive}
                  onChange={handleInputChange}
                />
              }
              label={
                auxResidentData.isActive
                  ? t('default.active')
                  : t('default.disabled')
              }
            />
          </FormGroup>
        </Box>
        <Divider />
        <Box mt={3} mb={1}>
          <ResidentOnlineAccount
            residentData={residentData}
            setRefreshData={setRefreshData}
            refreshData={refreshData}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <Button variant='outlined' color='error' href={`${API_URLS.tenants}`}>
          {t('default.cancel')}
        </Button>
        <Button
          color='warning'
          variant='contained'
          sx={{ ml: 2 }}
          onClick={handleSaveResidentChanges}
        >
          {t('default.save')}
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default ResidentInfo
