import { useState } from 'react'
import useAxiosPrivate from '../../api/hooks/useAxiosPrivate'
import useDownloadInvoice from '../pdf/useDownloadFile'
import useToastNotification from '../notifications/useToastNotification'

type PaymentData = {
  resident: string
  payments: {
    id: number
  }[]
  useResidentBalance: boolean
  totalAmount: number
}

type ResponsePaid = {
  id: number
  description: string
  amount: number
  block: string
}

export type LatePaymentResponseData = {
  paid: ResponsePaid[][]
  files: string[]
  newResidentBalance: number
  totalQuotesPaid: number
  totalAmountPaid: number
}

const useAddUserLateQuotesPayment = () => {
  const axiosPrivate = useAxiosPrivate()
  const { handleDownloadFile } = useDownloadInvoice()
  const { showApiErrorMessage } = useToastNotification()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [paymentDetails, setPaymentDetails] = useState<
    LatePaymentResponseData | undefined
  >(undefined)

  const handleAddUserALatePayment = async (paymentData: PaymentData) => {
    setIsSubmitting(true)

    await axiosPrivate
      .post('/api/payment-queues/late-quotes-payment', { ...paymentData })
      .then((response) => {
        const apiResponse: LatePaymentResponseData = response.data
        if (apiResponse.files.length > 0) {
          apiResponse.files.forEach((fileName, idx) => {
            handleDownloadFile(apiResponse.paid[idx][0].id, fileName)
          })
        }
        setPaymentDetails(apiResponse)
      })
      .catch((error) => {
        showApiErrorMessage(
          error,
          'Erro ao adicionar processar o(s) pagamento(s) em atraso.'
        )
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return {
    isSubmitting,
    handleAddUserALatePayment,
    paymentDetails,
    handleDownloadFile,
  }
}

export default useAddUserLateQuotesPayment
