import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import { AuthContext } from '../../../context/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Unauthorized from '../../auth/Unauthorized'

type SuperAdminMenuProps = {
  selectedMenu: string
  setSelectedMenu: (value: string) => void
}

const SuperAdminMenu = ({
  selectedMenu,
  setSelectedMenu,
}: SuperAdminMenuProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['home'])
  const { auth } = useContext(AuthContext)
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  const handleLeftMenuClick = (menu: string) => {
    setSelectedMenu(menu)
    navigate(`/admin/${menu}`)
  }

  if (!auth?.roles.includes('ROLE_SUPER_ADMIN')) {
    return <Unauthorized />
  }

  return (
    <React.Fragment>
      {/* Building Work */}
      <ListItemButton onClick={handleClick} sx={{ mt: 3 }}>
        <ListItemText
          primary={t('admin.superAdmin.menuText')}
          sx={{
            fontSize: '20px',
            '& .MuiListItemText-primary': {
              fontSize: '14px !important',
              fontWeight: 700,
            },
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'users'}
            onClick={() => handleLeftMenuClick('users')}
          >
            <ListItemIcon>
              <AdminPanelSettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.superAdmin.users.menuText')} />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            selected={selectedMenu === 'audit-logs'}
            onClick={() => handleLeftMenuClick('audit-logs')}
          >
            <ListItemIcon>
              <DescriptionOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={t('admin.superAdmin.auditLogs.menuText')} />
          </ListItemButton>
        </List>
      </Collapse>
    </React.Fragment>
  )
}

export default SuperAdminMenu
