import { createContext, useState } from 'react'
import { IAuth, TAuth, TAuthProvider } from './types/types'
import { getJwtDataFromLocalStorage } from '../utils/helper/helper'

const AuthContext = createContext<IAuth>({
  auth: {
    userId: 0,
    name: '',
    email: '',
    building: '',
    buildingId: '',
    buildingAccId: '',
    roles: [],
    token: '',
    refreshToken: '',
    expiration: 0,
  },
  setAuth: () => {},
})

const AuthProvider = ({ children }: TAuthProvider) => {
  const [auth, setAuth] = useState<TAuth | undefined>(
    getJwtDataFromLocalStorage()
  )

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
