import { Box } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

const Content = () => {
  return (
    <Box
      sx={{
        width: '100%',
        padding: '30px 15px',
        maxWidth: '1216px',
        margin: '0 auto',
      }}
    >
      <Outlet />
    </Box>
  )
}

export default Content
