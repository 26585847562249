import { TCustomChipDetails } from '../types/types'

export enum IdeaStatus {
  Pending = 1,
  Accepted = 2,
  InProgress = 3,
  Rejected = 4,
  Testing = 5,
  Completed = 6,
  NoStatus = 7,
}

export const IDEAS_STATUS: TCustomChipDetails[] = [
  {
    id: IdeaStatus.Pending,
    label: 'Em análise',
    variant: 'outlined',
    color: 'primary',
  },
  {
    id: IdeaStatus.Accepted,
    label: 'Lista de espera',
    variant: 'outlined',
    color: 'success',
  },
  {
    id: IdeaStatus.InProgress,
    label: 'Em desenvolvimento',
    variant: 'filled',
    color: 'warning',
  },
  {
    id: IdeaStatus.Rejected,
    label: 'Rejeitada',
    variant: 'filled',
    color: 'error',
  },
  {
    id: IdeaStatus.Testing,
    label: 'Em fase de testes',
    variant: 'filled',
    color: 'secondary',
  },
  {
    id: IdeaStatus.Completed,
    label: 'Concluída',
    variant: 'filled',
    color: 'success',
  },
]
