import React, { ChangeEvent, useContext, useState } from 'react'
import { TResidentUserAccountPopup } from '../types/types'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { convertDateToMonthYearFormat } from '../../../../utils/helper/helper'
import { AuthContext } from '../../../../context/AuthProvider'
import {
  addNewResidentUserAccountApi,
  editResidentUserAccountApi,
} from '../../../../utils/rest'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import useToastNotification from '../../../../hooks/notifications/useToastNotification'

const ResidentUserAccountPopup = ({
  userData,
  openUserPopup,
  setOpenUserPopup,
  residentId,
  setRefreshData,
  refreshData,
}: TResidentUserAccountPopup) => {
  const { auth } = useContext(AuthContext)
  const axiosPrivate = useAxiosPrivate()
  const { showSystemMessage } = useToastNotification()
  const { t } = useTranslation(['home'])
  const [formErrors, setFormErrors] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [disableSaveButton, setDisableSaveButton] = useState(false)
  const [auxUserDetails, setAuxUserDetails] = useState({
    email: userData?.email ?? '',
    password: '',
    isActive: userData?.isActive ?? true,
    roles: ['ROLE_USER'],
    building: auth?.buildingId,
    resident: residentId,
    isEditing: userData ? true : false,
  })

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setAuxUserDetails({
      ...auxUserDetails,
      [name]: type === 'checkbox' ? checked : value,
    })
  }

  const editUserAccount = async () => {
    setDisableSaveButton(true)

    const apiResponse = await editResidentUserAccountApi(
      axiosPrivate,
      auxUserDetails,
      userData?.id ?? 0
    )
    if (apiResponse.success) {
      setRefreshData(!refreshData)
      handleCancelButtonClick()
    } else {
      showSystemMessage('error', apiResponse.data)
    }

    setDisableSaveButton(false)
  }

  const addUserAccount = async () => {
    if (auxUserDetails.password.length === 0) {
      setFormErrors(t('errors.password'))

      return
    }

    setDisableSaveButton(true)

    const apiResponse = await addNewResidentUserAccountApi(
      axiosPrivate,
      auxUserDetails
    )
    if (apiResponse.success) {
      setRefreshData(true)
      handleCancelButtonClick()
    } else {
      showSystemMessage('error', apiResponse.data)
    }

    setDisableSaveButton(false)
  }

  const handleCancelButtonClick = () => {
    setOpenUserPopup(false)
    setFormErrors('')
  }

  const handleSaveUser = async () => {
    if (disableSaveButton) {
      return
    }

    if (auxUserDetails.email.length === 0) {
      setFormErrors(t('errors.email'))
    } else if (auxUserDetails.isEditing) {
      editUserAccount()
    } else {
      addUserAccount()
    }
  }

  return (
    <Dialog
      open={openUserPopup}
      onClose={() => setOpenUserPopup(false)}
      fullWidth
    >
      <DialogTitle sx={{ fontWeight: 700 }}>
        {auxUserDetails.isEditing
          ? t('admin.management.residents.edit.editAccount')
          : t('admin.management.residents.edit.createAccount')}
      </DialogTitle>

      <DialogContent>
        <Box component='form' autoComplete='off' mt={1} display={'flex'}>
          <Box width={'60%'}>
            <TextField
              name='email'
              label='Email'
              defaultValue={auxUserDetails.email}
              size='small'
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              required
            />

            <FormControl variant='outlined' size='small' fullWidth>
              <InputLabel htmlFor='password'>Password</InputLabel>
              <OutlinedInput
                id='password'
                name='password'
                type={
                  showPassword || auxUserDetails.password.length === 0
                    ? 'text'
                    : 'password'
                }
                onChange={handleInputChange}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
              />
            </FormControl>

            <FormGroup sx={{ mt: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    name='isActive'
                    checked={auxUserDetails.isActive}
                    onChange={handleInputChange}
                  />
                }
                label={
                  auxUserDetails.isActive
                    ? t('default.active')
                    : t('default.disabled')
                }
              />
            </FormGroup>
          </Box>

          <Divider orientation='vertical' flexItem sx={{ ml: 3, mr: 3 }} />

          <Box width={'40%'}>
            <Box mt={2}>
              <Box mb={2}>
                <b>{t('default.createdAt')}</b>
                <br />
                {userData?.createdAt
                  ? convertDateToMonthYearFormat(userData.createdAt)
                  : '-'}
              </Box>
              <Box>
                <b>{t('default.updatedAt')}</b>
                <br />
                {userData?.updatedAt
                  ? convertDateToMonthYearFormat(userData.updatedAt)
                  : '-'}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ mr: 3, mb: 2 }}>
        <Button
          variant='outlined'
          size='small'
          color='error'
          onClick={handleCancelButtonClick}
        >
          {t('default.cancel')}
        </Button>
        <Button
          variant='contained'
          size='small'
          color='warning'
          disabled={disableSaveButton}
          onClick={handleSaveUser}
        >
          {t('default.save')}
        </Button>
      </DialogActions>

      {formErrors && (
        <Alert severity='error' sx={{ marginTop: 3 }}>
          <AlertTitle>{t('login.errorTitle')}</AlertTitle>
          {formErrors}
        </Alert>
      )}
    </Dialog>
  )
}

export default ResidentUserAccountPopup
