import { AxiosInstance } from 'axios'
import { axiosBase } from '../api/axios'
import { getDataFilter, getIdFromUri, jwtDecoder } from './helper/helper'
import {
  TFilterPagination,
  TSetDataFilter,
} from '../components/admin/blocks/types/types'
import { TResidentListDataFilter } from '../components/admin/residents/types/types'

const getBuildingsApi = async (axiosPrivate: AxiosInstance) => {
  return await axiosPrivate
    .get('/api/buildings')
    .then((response) => {
      return response.data['hydra:member']
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const getUsersApi = async (axiosPrivate: AxiosInstance) => {
  return await axiosPrivate
    .get('/api/users')
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const loginUserApi = async (email: string, password: string) => {
  return await axiosBase
    .post('/api/tokens/authenticate', JSON.stringify({ email, password }))
    .then((response) => {
      return jwtDecoder(response.data)
    })
    .catch((err) => {
      addAccessErrorLogApi(err)

      return undefined
    })
}

const addAccessErrorLogApi = async (errorData: any) => {
  const log = {
    message: errorData.message ?? 'N/A',
    name: errorData.name ?? 'N/A',
    code: errorData.code ?? 'N/A',
    config: errorData.config ?? 'N/A',
    request: errorData.request ?? 'N/A',
    response: errorData.response ?? 'N/A',
  }

  const errorLog = {
    log: JSON.stringify(log),
    type: 'ACCESS_ERROR',
  }

  return await axiosBase.post('/add-access-error-log', JSON.stringify(errorLog))
}

const sendErrorLogApi = async (axiosPrivate: AxiosInstance, errorData: any) => {
  const errorLog = {
    log: {
      message: errorData.message ?? 'N/A',
      name: errorData.name ?? 'N/A',
      code: errorData.code ?? 'N/A',
      config: errorData.config ?? 'N/A',
      request: errorData.request ?? 'N/A',
      response: errorData.response ?? 'N/A',
    },
    type: 'API_ERROR',
  }

  return await axiosPrivate
    .post('/api/add-error-log', JSON.stringify(errorLog))
    .catch((err) => {
      return {
        success: false,
        error: err,
      }
    })
}

const getBlocksApiData = async (axiosPrivate: AxiosInstance) => {
  return await axiosPrivate
    .get('/api/blocks?pagination=false')
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const getSingleBlocksApiData = async (
  axiosPrivate: AxiosInstance,
  blockId: string | undefined
) => {
  return await axiosPrivate
    .get(`/api/blocks/${blockId}`)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((err: any) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: [],
      }
    })
}

const getPaymentQueueForSingleResidentBlockApiData = async (
  axiosPrivate: AxiosInstance,
  residentBlockId: string | number,
  dataFilters: TSetDataFilter
) => {
  return await axiosPrivate
    .get(
      `/api/payment-queues/resident-block/${residentBlockId}${getDataFilter(
        dataFilters
      )}`
    )
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err: any) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: [],
      }
    })
}

const getPaymentQueueForSpecificResidentApiData = async (
  axiosPrivate: AxiosInstance,
  residentBlockId: string | number,
  dataFilters: TSetDataFilter | TFilterPagination,
  paymentType?: string
) => {
  let url = `/api/payment-queues/resident/${residentBlockId}${getDataFilter(
    dataFilters
  )}`

  if (paymentType && paymentType !== '') {
    url += `&type=${paymentType}&pagination=false`
  }

  return await axiosPrivate
    .get(url)
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const getPaymentsListByBuildingApi = async (
  axiosPrivate: AxiosInstance,
  buildingAccId: string,
  dataFilters: TSetDataFilter
) => {
  return await axiosPrivate
    .get(
      `/api/building-account-statements/building-acc/${buildingAccId}${getDataFilter(
        dataFilters
      )}`
    )
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const getPaymentsListResidentBlocksOnlyApi = async (
  axiosPrivate: AxiosInstance,
  dataFilters: TSetDataFilter
) => {
  return await axiosPrivate
    .get(
      `/api/payment-queues/resident-blocks-only${getDataFilter(dataFilters)}`
    )
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err: any) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: [],
      }
    })
}

const getPaymentsListWorksOnlyApi = async (
  axiosPrivate: AxiosInstance,
  dataFilters: TSetDataFilter
) => {
  return await axiosPrivate
    .get(`/api/payment-queues/works-only${getDataFilter(dataFilters)}`)
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const getResidentsApiData = async (
  axiosPrivate: AxiosInstance,
  dataFilters: TResidentListDataFilter
) => {
  let filtering = `?page=${dataFilters.page + 1}`
  if (dataFilters.name.length > 0) {
    filtering += `&name=${dataFilters.name}`
  }

  if (dataFilters.isActive !== null) {
    filtering += `&isActive=${dataFilters.isActive ? 1 : 0}`
  }

  return await axiosPrivate
    .get(`/api/residents${filtering}`)
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: [],
      }
    })
}

const getActiveResidentsApiData = async (axiosPrivate: AxiosInstance) => {
  return await axiosPrivate
    .get('/api/residents?pagination=false&isActive=1&order[name]=asc')
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: [],
      }
    })
}

const getOldResidentsFromBlockApiData = async (
  axiosPrivate: AxiosInstance,
  blockId: number,
  page: number
) => {
  return await axiosPrivate
    .get(`/api/resident-blocks/block/${blockId}?page=${page + 1}`)
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
      }
    })
}

const getOldBlocksForSingleResidentApiData = async (
  axiosPrivate: AxiosInstance,
  residentId: number,
  page: number
) => {
  return await axiosPrivate
    .get(`/api/resident-blocks/resident/${residentId}?page=${page + 1}`)
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
        totalItems: response.data['hydra:totalItems'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
      }
    })
}

const addNewResidentBlockApi = async (
  axiosPrivate: AxiosInstance,
  blockId: string,
  residentId: string
) => {
  return await axiosPrivate
    .post('/api/resident-blocks', JSON.stringify({ blockId, residentId }))
    .then((response) => {
      return {
        success: true,
        error: '',
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        error: err,
      }
    })
}

const getSingleResidentApiData = async (
  axiosPrivate: AxiosInstance,
  residentId: string | undefined
) => {
  return await axiosPrivate
    .get(`/api/residents/${residentId}`)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((err: any) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: [],
      }
    })
}

const addNewResidentUserAccountApi = async (
  axiosPrivate: AxiosInstance,
  payload: object
) => {
  return await axiosPrivate
    .post('/api/users', JSON.stringify({ ...payload }))
    .then(() => {
      return {
        success: true,
        data: [],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const editResidentUserAccountApi = async (
  axiosPrivate: AxiosInstance,
  payload: object,
  userId: number
) => {
  return await axiosPrivate
    .patch(`/api/users/${userId}`, JSON.stringify({ ...payload }))
    .then(() => {
      return {
        success: true,
        data: [],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const editResidentApi = async (
  axiosPrivate: AxiosInstance,
  payload: object,
  residentId: number
) => {
  return await axiosPrivate
    .patch(`/api/residents/${residentId}`, JSON.stringify({ ...payload }))
    .then(() => {
      return {
        success: true,
        data: [],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const addNewResidentApi = async (
  axiosPrivate: AxiosInstance,
  payload: object
) => {
  return await axiosPrivate
    .post('/api/residents', JSON.stringify({ ...payload }))
    .then((response) => {
      return {
        success: true,
        data: response.data['@id'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const getBuildingAccountBalanceApi = async (
  axiosPrivate: AxiosInstance,
  buildingId: string
) => {
  return await axiosPrivate
    .get(`/api/building-account/balances/${getIdFromUri(buildingId)}`)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const getResidentsWithLatePaymentsApi = async (axiosPrivate: AxiosInstance) => {
  return await axiosPrivate
    .get('/api/payment-queues/late-payments?pagination=false')
    .then((response) => {
      return {
        success: true,
        data: response.data['hydra:member'],
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        data: err,
      }
    })
}

const sendPaymentsListApi = async (
  axiosPrivate: AxiosInstance,
  payload: object
) => {
  return await axiosPrivate
    .post('/api/users', JSON.stringify({ ...payload }))
    .then((response) => {
      return {
        success: true,
      }
    })
    .catch((err) => {
      sendErrorLogApi(axiosPrivate, err)

      return {
        success: false,
        error: err,
      }
    })
}

export {
  getBuildingsApi,
  getUsersApi,
  loginUserApi,
  getBlocksApiData,
  getSingleBlocksApiData,
  sendErrorLogApi,
  getPaymentQueueForSingleResidentBlockApiData,
  getPaymentQueueForSpecificResidentApiData,
  getPaymentsListByBuildingApi,
  getResidentsApiData,
  getActiveResidentsApiData,
  getOldResidentsFromBlockApiData,
  getOldBlocksForSingleResidentApiData,
  addNewResidentBlockApi,
  getSingleResidentApiData,
  addNewResidentUserAccountApi,
  editResidentUserAccountApi,
  editResidentApi,
  addNewResidentApi,
  getBuildingAccountBalanceApi,
  getPaymentsListResidentBlocksOnlyApi,
  getPaymentsListWorksOnlyApi,
  getResidentsWithLatePaymentsApi,
  sendPaymentsListApi,
}
