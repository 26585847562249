import { CircularProgress } from '@mui/material'

const LoadingCircular = () => {
  return (
    <CircularProgress
      size={22}
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: '-12px',
        marginLeft: '-12px',
      }}
    />
  )
}

export default LoadingCircular
