import React from 'react'
import { File } from '../../../interfaces/file'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import { TFileList } from './types/types'
import useDownloadInvoice from '../../../hooks/pdf/useDownloadFile'
import { Receipt, RequestQuote } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

const FileList = ({ files, paymentId }: TFileList) => {
  const { handleDownloadFile } = useDownloadInvoice()

  return (
    <React.Fragment>
      {files.map((file: File) => {
        if (file.type === 1) {
          return (
            <Tooltip title='Fatura' key={file.id}>
              <RequestQuote
                color='primary'
                sx={{ cursor: 'pointer' }}
                onClick={(_) => handleDownloadFile(paymentId, file.name)}
              />
            </Tooltip>
          )
        }

        if (file.type === 2) {
          return (
            <Tooltip title='Recibo' key={file.id}>
              <Receipt
                key={file.id}
                color='primary'
                sx={{ cursor: 'pointer' }}
                onClick={(_) => handleDownloadFile(paymentId, file.name)}
              />
            </Tooltip>
          )
        }

        return (
          <Tooltip title='Outro' key={file.id}>
            <AttachFileOutlinedIcon
              key={file.id}
              color='primary'
              sx={{ cursor: 'pointer' }}
              onClick={(_) => handleDownloadFile(paymentId, file.name)}
            />
          </Tooltip>
        )
      })}
    </React.Fragment>
  )
}

export default FileList
