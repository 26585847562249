import React, { useContext, useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import useAxiosPrivate from '../../../api/hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import { BuildAccountStatement, TPaymentsSummary } from './types/types'
import { getPaymentsListByBuildingApi } from '../../../utils/rest'
import {
  convertDateToFullFormat,
  numberFormatting,
} from '../../../utils/helper/helper'
import PageWithoutData from '../../../pages/admin/PageWithoutData'
import LoadingCircular from '../../defaults/Loading'
import { TSetDataFilter } from '../blocks/types/types'
import { AuthContext } from '../../../context/AuthProvider'
import useToastNotification from '../../../hooks/notifications/useToastNotification'

const AllPaymentsList = () => {
  const axiosPrivate = useAxiosPrivate()
  const { auth } = useContext(AuthContext)
  const { t } = useTranslation(['home'])
  const { showApiErrorMessage } = useToastNotification()
  const [payments, setPayments] = useState<BuildAccountStatement[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [errorFetching, setErrorFetching] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [dataFilters, setDataFilters] = useState<TSetDataFilter>({
    type: 'listFilter',
    paid: true,
    notPaid: true,
    fromDate: null,
    toDate: null,
    page: 0,
  })

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setDataFilters({
      ...dataFilters,
      page: newPage,
    })
  }

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true)

      const apiResponse: TPaymentsSummary = await getPaymentsListByBuildingApi(
        axiosPrivate,
        auth?.buildingAccId ?? '',
        dataFilters
      )
      if (!apiResponse.success) {
        showApiErrorMessage(apiResponse.data, `${t('errors.getDataError')}`)
        setErrorFetching(true)
      } else {
        setPayments(apiResponse.data)
        setTotalItems(apiResponse.totalItems ?? 0)
      }

      setLoading(false)
    }

    fetchPayments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilters])

  return (
    <React.Fragment>
      {errorFetching ? (
        <PageWithoutData />
      ) : (
        <React.Fragment>
          {loading && <LoadingCircular />}
          <TableContainer
            component={Paper}
            sx={{ opacity: `${loading ? 0.2 : 1}` }}
          >
            <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Referência</TableCell>
                  <TableCell>
                    {t(
                      'admin.management.blocks.edit.tenantPayments.description'
                    )}
                  </TableCell>
                  <TableCell>{t('default.createdAt')}</TableCell>
                  <TableCell>{t('default.amount')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment: BuildAccountStatement) => {
                  return (
                    <TableRow key={payment.id}>
                      <TableCell component='th' scope='row'>
                        {payment.id}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {payment.reference}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {payment.description}
                      </TableCell>
                      <TableCell>
                        {convertDateToFullFormat(payment.createdAt)}
                      </TableCell>
                      <TableCell>{numberFormatting(payment.amount)}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ width: '100%' }}>
                  <TablePagination
                    count={totalItems}
                    rowsPerPage={10}
                    page={dataFilters.page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage=''
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default AllPaymentsList
