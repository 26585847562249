import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TBlockInfo } from '../types/types'
import { addNewResidentBlockApi } from '../../../../utils/rest'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import { API_URLS } from '../../../../utils/helper/helper'
import useToastNotification from '../../../../hooks/notifications/useToastNotification'
const BlockInfo = ({ blockData, residentsData }: TBlockInfo) => {
  const { t } = useTranslation(['home'])
  const axiosPrivate = useAxiosPrivate()
  const { showSystemMessage, showApiErrorMessage } = useToastNotification()
  const [openSelectDropdown, setOpenSelectDropdown] = useState(false)
  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [residentId, setResidentId] = useState<string>(
    blockData.residentBlocks[0].resident['@id']
  )

  const handleChange = (event: SelectChangeEvent<typeof residentId>) => {
    const selectValue = event.target.value
    setResidentId(selectValue)

    if (selectValue !== blockData.residentBlocks[0].resident['@id']) {
      setDisableSaveButton(false)
    } else {
      setDisableSaveButton(true)
    }
  }

  const getResidentBlock = (resident: any) => {
    if (!resident.blocks) {
      return ''
    }

    let blocks = resident.blocks.map((block: any) => {
      return ` (${block.lotNumber}) `
    })

    return <small>{blocks}</small>
  }

  const handleClose = () => {
    setOpenSelectDropdown(false)
  }

  const handleOpen = () => {
    setOpenSelectDropdown(true)
  }

  const handleSaveNewResidentBlock = async () => {
    const response = await addNewResidentBlockApi(
      axiosPrivate,
      blockData['@id'],
      residentId
    )

    if (response.success) {
      showSystemMessage('success', 'Novo residente adicionado com sucesso')
    } else {
      showApiErrorMessage(response.error, 'Erro ao adicionar novo residente')
    }
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          '& .MuiTextField-root': { m: '16px 8px' },
        }}
        className='box-shadow'
      >
        <div style={{ display: 'flex' }}>
          <TextField
            label='Block Number'
            defaultValue={blockData.lotNumber}
            size='small'
            disabled
            sx={{ width: '50%' }}
          />
          <TextField
            label='Block Permillage'
            defaultValue={blockData.lotFraction + '%'}
            size='small'
            disabled
            sx={{ width: '50%' }}
          />
        </div>
        <div style={{ margin: '16px 8px' }}>
          <FormControl fullWidth>
            <InputLabel id='demo-controlled-open-select-label' size='small'>
              {t('default.tenant')}
            </InputLabel>
            <Select
              labelId='demo-controlled-open-select-label'
              open={openSelectDropdown}
              onClose={handleClose}
              onOpen={handleOpen}
              value={residentId}
              label={t('default.tenant')}
              onChange={handleChange}
              size='small'
              fullWidth
            >
              <MenuItem disabled value=''>
                <em>
                  {t('admin.management.blocks.edit.selectResidentPlaceholder')}
                </em>
              </MenuItem>
              {residentsData.map((resident) => (
                <MenuItem key={resident.id} value={resident['@id']}>
                  {resident.name}&nbsp;{getResidentBlock(resident)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Box>
      <div style={{ marginTop: '30px' }}>
        <Button
          color='warning'
          variant='contained'
          disabled={disableSaveButton}
          sx={{ mr: 2 }}
          onClick={handleSaveNewResidentBlock}
        >
          {t('default.save')}
        </Button>
        <Button variant='outlined' href={`${API_URLS.blocks}`}>
          {t('default.cancel')}
        </Button>
      </div>
    </React.Fragment>
  )
}

export default BlockInfo
