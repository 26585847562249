import { Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import useAxiosPrivate from '../../../api/hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import { getBuildingAccountBalanceApi } from '../../../utils/rest'
import { BuildingAccount } from '../../../interfaces/buildingaccount'
import { numberFormatting } from '../../../utils/helper/helper'
import { AuthContext } from '../../../context/AuthProvider'
import LoadingCircular from '../../defaults/Loading'
import useToastNotification from '../../../hooks/notifications/useToastNotification'

const ToReceiveBoxes = () => {
  const axiosPrivate = useAxiosPrivate()
  const { showApiErrorMessage } = useToastNotification()
  const { auth } = useContext(AuthContext)
  const { t } = useTranslation(['home'])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<BuildingAccount>({
    balance: 0,
    rentTotalToPay: 0,
    workTotalToPay: 0,
  })

  useEffect(() => {
    const LoaderData = async () => {
      setIsLoading(true)

      const apiResponse = await getBuildingAccountBalanceApi(
        axiosPrivate,
        auth?.buildingId ?? ''
      )

      if (!apiResponse.success) {
        showApiErrorMessage(apiResponse.data, `${t('errors.getDataError')}`)
      } else {
        setData(apiResponse.data)
      }

      setIsLoading(false)
    }

    LoaderData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingCircular />
      ) : (
        <Grid container spacing={2} mt={2}>
          <Grid item xs={4}>
            <Card>
              <CardContent
                sx={{
                  '&.MuiCardContent-root': {
                    pb: 1,
                  },
                }}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 700 }}
                  color='text.secondary'
                  gutterBottom
                >
                  {t('admin.management.accounting.list.boxes.balance')}
                </Typography>

                <Typography
                  sx={{
                    fontSize: 26,
                    fontWeight: 700,
                    color: data.balance > 0 ? 'green' : 'red',
                  }}
                  color='#000'
                  gutterBottom
                >
                  {numberFormatting(data.balance)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent
                sx={{
                  '&.MuiCardContent-root': {
                    pb: 1,
                  },
                }}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 700 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Manutenções a receber
                </Typography>

                <Typography
                  sx={{ fontSize: 26, fontWeight: 700 }}
                  color='#000'
                  gutterBottom
                >
                  {numberFormatting(
                    data.rentTotalToPay < 0 ? 0 : data.rentTotalToPay
                  )}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent
                sx={{
                  '&.MuiCardContent-root': {
                    pb: 1,
                  },
                }}
              >
                <Typography
                  sx={{ fontSize: 14, fontWeight: 700 }}
                  color='text.secondary'
                  gutterBottom
                >
                  {t('admin.management.accounting.list.boxes.workToReceive')}
                </Typography>

                <Typography
                  sx={{ fontSize: 26, fontWeight: 700 }}
                  color='#000'
                  gutterBottom
                >
                  {numberFormatting(data.workTotalToPay)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default ToReceiveBoxes
