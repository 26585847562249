import React, { useEffect, useState } from 'react'
import { getPaymentQueueForSpecificResidentApiData } from '../../../../utils/rest'
import { PaymentQueue } from '../../../../interfaces/paymentqueue'
import { useTranslation } from 'react-i18next'
import {
  TResidentPaymentApiList,
  TResidentPaymentsList,
  TResidentSetDataFilter,
} from '../types/types'
import LoadingCircular from '../../../defaults/Loading'
import PageWithoutData from '../../../../pages/admin/PageWithoutData'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import {
  API_URLS,
  convertDateToFullFormat,
  numberFormatting,
} from '../../../../utils/helper/helper'
import ActiveIcon from '../../../../pages/admin/layout/ActiveIcon'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import useAxiosPrivate from '../../../../api/hooks/useAxiosPrivate'
import ResidentPaymentsFilters from './ResidentPaymentsFilters'
import FileList from '../../files/FileList'
import { useNavigate } from 'react-router-dom'
import { MAINTENANCE_TYPES } from '../../../../pages/admin/maintenance/AddMaintenance'

const ResidentPaymentsList = ({ resident }: TResidentPaymentsList) => {
  const { t } = useTranslation(['home'])
  const navigate = useNavigate()
  const axiosPrivate = useAxiosPrivate()
  const [loading, setLoading] = useState(true)
  const [errorFetching, setErrorFetching] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [residentPayments, setResidentPayments] = useState<PaymentQueue[]>([])
  const [openFiltersDialog, setOpenFiltersDialog] = useState(false)
  const [paymentType, setPaymentType] = useState<string>('')
  const [dataFilters, setDataFilters] = useState<TResidentSetDataFilter>({
    type: 'listFilter',
    paid: true,
    notPaid: true,
    fromDate: null,
    toDate: null,
    page: 0,
  })

  let totalMaintenance = 0

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setDataFilters({
      ...dataFilters,
      page: newPage,
    })
  }

  useEffect(() => {
    const fetchResidentPayments = async () => {
      setLoading(true)

      const apiResponse: TResidentPaymentApiList =
        await getPaymentQueueForSpecificResidentApiData(
          axiosPrivate,
          resident.id,
          dataFilters,
          paymentType
        )

      if (!apiResponse.success) {
        setErrorFetching(true)
      } else {
        setResidentPayments(apiResponse.data)
        setTotalItems(apiResponse.totalItems ?? 0)
      }

      setLoading(false)
    }

    fetchResidentPayments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilters, paymentType])

  return (
    <React.Fragment>
      {loading ? (
        <LoadingCircular />
      ) : errorFetching ? (
        <PageWithoutData />
      ) : (
        <React.Fragment>
          <Box display={'flex'}>
            <Typography variant='h2' className='content-title'>
              {t('admin.management.blocks.edit.tenantPayments.title')}{' '}
              {resident.name}
            </Typography>
            <Box ml={'auto'}>
              <Button
                color='warning'
                variant='contained'
                sx={{ mr: 1 }}
                onClick={() => navigate(`${API_URLS.accounting}/new`)}
              >
                {t('admin.management.blocks.edit.newPayment')}
              </Button>

              <FormControl sx={{ mr: 1, minWidth: 230 }}>
                <InputLabel id='paymentType' size='small'>
                  Tipo de Pagamento
                </InputLabel>
                <Select
                  name='paymentType'
                  labelId='paymentType'
                  label='Tipo de Pagamento'
                  size='small'
                  sx={{ textAlign: 'left' }}
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  <MenuItem disabled value='-1'>
                    <em>Selecione o tipo de manutencao</em>
                  </MenuItem>
                  <MenuItem value=''>Todos os tipos</MenuItem>
                  {MAINTENANCE_TYPES.map((maintenance) => (
                    <MenuItem key={maintenance.id} value={maintenance.id}>
                      {maintenance.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Button
                variant='outlined'
                onClick={() => setOpenFiltersDialog(true)}
              >
                <FilterListOutlinedIcon />
                &nbsp;
                {t('default.filters')}
              </Button>
            </Box>
          </Box>
          <ResidentPaymentsFilters
            open={openFiltersDialog}
            setOpen={setOpenFiltersDialog}
            dataFilters={dataFilters}
            setDataFilters={setDataFilters}
          />
          <TableContainer component={Paper}>
            <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('default.fraction')}</TableCell>
                  <TableCell>
                    {t(
                      'admin.management.blocks.edit.tenantPayments.description'
                    )}
                  </TableCell>
                  <TableCell>{t('default.createdAt')}</TableCell>
                  <TableCell>{t('default.paid')}?</TableCell>
                  <TableCell>{t('default.amount')}</TableCell>
                  <TableCell>{t('default.lastPaymentAt')}</TableCell>
                  <TableCell>
                    {t('admin.management.blocks.edit.tenantPayments.files')}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {residentPayments.map((residentPayment: PaymentQueue) => {
                  totalMaintenance += residentPayment.amount
                  return (
                    <TableRow key={residentPayment.id}>
                      <TableCell component='th' scope='row'>
                        {residentPayment.residentBlock?.block.lotLetter}
                        {`) `}
                        {residentPayment.residentBlock?.block.lotNumber}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {residentPayment.description}
                      </TableCell>
                      <TableCell>
                        {convertDateToFullFormat(residentPayment.createdAt)}
                      </TableCell>
                      <TableCell
                        component='th'
                        scope='row'
                        sx={{ textAlign: 'center' }}
                      >
                        <ActiveIcon isActive={residentPayment.isFullyPaid} />
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <Box>
                          <small>
                            <b>Total:</b>{' '}
                          </small>
                          {numberFormatting(residentPayment.amount)}
                        </Box>
                        <Box>
                          <small>
                            <b style={{ color: 'green' }}>
                              {t('default.paid')}:
                            </b>{' '}
                          </small>
                          {numberFormatting(residentPayment.amountAlreadyPaid)}
                        </Box>
                        {!residentPayment.isFullyPaid && (
                          <Box>
                            <small>
                              <b style={{ color: 'red' }}>
                                {t('default.toPay')}:
                              </b>{' '}
                            </small>
                            {numberFormatting(
                              residentPayment.amount -
                                residentPayment.amountAlreadyPaid
                            )}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>
                        {convertDateToFullFormat(residentPayment.lastPaymentAt)}
                      </TableCell>

                      <TableCell>
                        <FileList
                          files={residentPayment.files}
                          paymentId={residentPayment.id}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}

                {paymentType !== '' && (
                  <React.Fragment>
                    <TableRow sx={{ backgroundColor: '#f4f4f5' }}>
                      <TableCell
                        component='th'
                        scope='row'
                        colSpan={4}
                        sx={{ fontWeight: 700, padding: '8px 16px' }}
                      >
                        {t('admin.management.blocks.list.summary')}
                      </TableCell>
                      <TableCell
                        component='th'
                        scope='row'
                        colSpan={4}
                        sx={{ fontWeight: 700, padding: '8px 16px' }}
                      >
                        Total a pagar
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th' scope='row' colSpan={4}>
                        {
                          MAINTENANCE_TYPES.filter(
                            (maintenance) =>
                              maintenance.id === Number(paymentType)
                          )[0].label
                        }
                      </TableCell>
                      <TableCell component='th' scope='row' colSpan={4}>
                        &nbsp;{numberFormatting(totalMaintenance)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
              </TableBody>
              {paymentType === '' && (
                <TableFooter>
                  <TableRow sx={{ width: '100%' }}>
                    <TablePagination
                      count={totalItems}
                      rowsPerPage={10}
                      page={dataFilters.page}
                      onPageChange={handleChangePage}
                      labelRowsPerPage=''
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default ResidentPaymentsList
