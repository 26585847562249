import { VpnLock } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { API_URLS } from '../../utils/helper/helper'
import { AuthContext } from '../../context/AuthProvider'

const Unauthorized = () => {
  const navigate = useNavigate()
  const { setAuth } = useContext(AuthContext)

  return (
    <React.Fragment>
      <Box
        sx={{ marginTop: '100px' }}
        display={'flex'}
        alignItems={'top'}
        justifyContent={'center'}
      >
        <Box mr={4} mt={1.5}>
          <VpnLock sx={{ fontSize: 80, color: 'var(--bay-of-many)' }} />
        </Box>
        <Box>
          <Typography variant='h1' fontWeight={700} color={'#BBBBBB'}>
            401
          </Typography>
          <Typography variant='h5' fontWeight={700}>
            Pedido não autorizado
          </Typography>
          <Typography variant='h5' mt={2}>
            Não está autenticado ou não tem acesso à página solicitada.
          </Typography>
          <Typography variant='h5' mt={4} mb={2}>
            Contacte o suporte se este erro continuar.
          </Typography>

          <Button
            variant='contained'
            color='primary'
            size='large'
            sx={{ width: 150 }}
            onClick={() => navigate(API_URLS.homepage)}
          >
            Página inicial
          </Button>

          <Button
            variant='contained'
            color='primary'
            size='large'
            sx={{ ml: 2, width: 150 }}
            onClick={() => {
              localStorage.removeItem('token')
              localStorage.removeItem('refreshToken')
              setAuth(undefined)

              navigate('/login')
            }}
          >
            Sair
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default Unauthorized
