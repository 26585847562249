import React, { useEffect, useState } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import useAxiosPrivate from '../../../api/hooks/useAxiosPrivate'
import { useTranslation } from 'react-i18next'
import { PaymentQueue } from '../../../interfaces/paymentqueue'
import { TPaymentsList } from './types/types'
import { getPaymentsListWorksOnlyApi } from '../../../utils/rest'
import {
  convertDateToFullFormat,
  numberFormatting,
} from '../../../utils/helper/helper'
import ActiveIcon from '../../../pages/admin/layout/ActiveIcon'
import FileList from '../files/FileList'
import PageWithoutData from '../../../pages/admin/PageWithoutData'
import LoadingCircular from '../../defaults/Loading'
import ListFilters from './ListFilters'
import { TSetDataFilter } from '../blocks/types/types'
import useToastNotification from '../../../hooks/notifications/useToastNotification'

const WorksPaymentQueueList = () => {
  const axiosPrivate = useAxiosPrivate()
  const { t } = useTranslation(['home'])
  const { showApiErrorMessage } = useToastNotification()
  const [payments, setPayments] = useState<PaymentQueue[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [errorFetching, setErrorFetching] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [dataFilters, setDataFilters] = useState<TSetDataFilter>({
    type: 'listFilter',
    paid: true,
    notPaid: true,
    fromDate: null,
    toDate: null,
    page: 0,
  })

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setDataFilters({
      ...dataFilters,
      page: newPage,
    })
  }

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true)

      const apiResponse: TPaymentsList = await getPaymentsListWorksOnlyApi(
        axiosPrivate,
        dataFilters
      )
      if (!apiResponse.success) {
        showApiErrorMessage(apiResponse.data, `${t('errors.getDataError')}`)
        setErrorFetching(true)
      } else {
        setPayments(apiResponse.data)
        setTotalItems(apiResponse.totalItems ?? 0)
      }

      setLoading(false)
    }

    fetchPayments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilters])

  return (
    <React.Fragment>
      {errorFetching ? (
        <PageWithoutData />
      ) : (
        <React.Fragment>
          <ListFilters
            dataFilters={dataFilters}
            setDataFilters={setDataFilters}
          />
          {loading && <LoadingCircular />}
          <TableContainer
            component={Paper}
            sx={{ opacity: `${loading ? 0.2 : 1}` }}
          >
            <Table aria-label='simple table' sx={{ minWidth: '650px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('default.type')}</TableCell>
                  <TableCell>
                    {t(
                      'admin.management.blocks.edit.tenantPayments.description'
                    )}
                  </TableCell>
                  <TableCell>{t('default.createdAt')}</TableCell>
                  <TableCell>{t('default.paid')}?</TableCell>
                  <TableCell>{t('default.amount')}</TableCell>
                  <TableCell>{t('default.lastPaymentAt')}</TableCell>
                  <TableCell>
                    {t('admin.management.blocks.edit.tenantPayments.files')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment: PaymentQueue) => {
                  return (
                    <TableRow key={payment.id}>
                      <TableCell component='th' scope='row'>
                        {payment.work
                          ? t('default.works')
                          : t('default.condominium')}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {payment.description}
                      </TableCell>
                      <TableCell>
                        {convertDateToFullFormat(payment.createdAt)}
                      </TableCell>
                      <TableCell
                        component='th'
                        scope='row'
                        sx={{ textAlign: 'center' }}
                      >
                        <ActiveIcon isActive={payment.isFullyPaid} />
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        <Box>
                          <small>
                            <b>Total:</b>{' '}
                          </small>
                          {numberFormatting(payment.amount)}
                        </Box>
                        <Box>
                          <small>
                            <b style={{ color: 'green' }}>
                              {t('default.paid')}:
                            </b>{' '}
                          </small>
                          {numberFormatting(payment.amountAlreadyPaid)}
                        </Box>
                        {!payment.isFullyPaid && (
                          <Box>
                            <small>
                              <b style={{ color: 'red' }}>
                                {t('default.toPay')}:
                              </b>{' '}
                            </small>
                            {numberFormatting(
                              payment.amount - payment.amountAlreadyPaid
                            )}
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>
                        {convertDateToFullFormat(payment.lastPaymentAt)}
                      </TableCell>

                      <TableCell>
                        <FileList
                          files={payment.files}
                          paymentId={payment.id}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow sx={{ width: '100%' }}>
                  <TablePagination
                    count={totalItems}
                    rowsPerPage={10}
                    page={dataFilters.page}
                    onPageChange={handleChangePage}
                    labelRowsPerPage=''
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default WorksPaymentQueueList
