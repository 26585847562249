import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import RequireAuth from './components/auth/RequireAuth'
import Unauthorized from './pages/auth/Unauthorized'
import AdminHome from './pages/admin/home/Home'
import AdminLayout from './pages/admin/layout/Layout'
import Login from './pages/auth/Login'
import UsersList from './pages/admin/users/UsersList'
import FilesList from './pages/admin/files/FilesList'
import WorksList from './pages/admin/works/WorksList'
import AuditLogsList from './pages/admin/auditLogs/AuditLogsList'
import BlocksList from './pages/admin/blocks/BlocksList'
import UsersDetail from './pages/admin/users/UsersDetail'
import AccountingList from './pages/admin/accounting/AccountingList'
import MaintenanceList from './pages/admin/maintenance/MaintenanceList'
// import ManagementList from './pages/admin/management/ManagementList'
import TenantsList from './pages/admin/tenants/TenantsList'
import BlockDetails from './pages/admin/blocks/BlockDetails'
import './App.css'
import TenantDetails from './pages/admin/tenants/TenantDetails'
import AddMaintenance from './pages/admin/maintenance/AddMaintenance'
import NewAccounting from './pages/admin/accounting/NewAccounting'
import ChangePassword from './pages/admin/ChangePassword'
import ReportsMain from './pages/admin/reports/ReportsMain'
import IdeasPage from './pages/admin/ideas/IdeasPage'
import IdeasEdit from './pages/admin/ideas/IdeasEdit'

// const Home = lazy(() => import('./pages/home/Home'))
const Missing = lazy(() => import('./pages/Missing'))

const App = () => {
  return (
    <React.Fragment>
      <Suspense fallback={<div className='container'>Loading...</div>}>
        <Routes>
          {/* Custom pages */}
          {/* <Route path='/' element={<AdminLayout />}> */}
          <Route path='' element={<Login />} />
          {/* </Route> */}

          {/* Auth pages */}
          <Route path='/login' element={<Login />} />

          {/* Admin pages */}
          <Route element={<RequireAuth requiredRoles={['ROLE_USER']} />}>
            <Route path='/admin/' element={<AdminLayout />}>
              <Route path='' element={<AdminHome />} />

              <Route element={<RequireAuth requiredRoles={['ROLE_ADMIN']} />}>
                {/* <Route path='management' element={<ManagementList />} /> */}
                <Route path='blocks' element={<BlocksList />} />
                <Route path='blocks/:id' element={<BlockDetails />} />

                <Route path='tenants' element={<TenantsList />} />
                <Route path='tenants/:id' element={<TenantDetails />} />

                <Route path='files' element={<FilesList />} />
                <Route path='works' element={<WorksList />} />

                <Route path='maintenance' element={<MaintenanceList />} />
                <Route path='maintenance/new' element={<AddMaintenance />} />

                <Route path='accounting' element={<AccountingList />} />
                <Route path='accounting/new' element={<NewAccounting />} />

                <Route path='reports' element={<ReportsMain />} />

                <Route path='ideas' element={<IdeasPage />} />
                <Route path='ideas/:ideaId' element={<IdeasEdit />} />

                <Route path='change-password' element={<ChangePassword />} />
              </Route>

              <Route
                element={<RequireAuth requiredRoles={['ROLE_SUPER_ADMIN']} />}
              >
                <Route path='users' element={<UsersList />} />
                <Route path='users/:id' element={<UsersDetail />} />
                <Route path='audit-logs' element={<AuditLogsList />} />
              </Route>
            </Route>
          </Route>

          <Route path='/unauthorized' element={<Unauthorized />} />

          {/* catch all */}
          <Route path='*' element={<Missing />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  )
}

export default App
