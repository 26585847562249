import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import image from '../../images/pageNotFound.png'

const PageNotFound = () => {
  const { t } = useTranslation(['home'])

  return (
    <React.Fragment>
      <Box sx={{ textAlign: 'center' }}>
        <img src={image} alt='Page not found' width={'50%'} />
        <Typography
          variant='h2'
          sx={{
            fontWeight: 700,
            fontSize: '1.5rem',
            lineHeight: '1.375',
            mb: 3,
          }}
        >
          {t('admin.pageNotFound.title')}
        </Typography>
        <Box>{t('admin.pageNotFound.description')}</Box>
        <Button
          variant='contained'
          color='warning'
          href='/admin'
          sx={{ mt: 3 }}
        >
          {t('admin.pageNotFound.buttonText')}
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default PageNotFound
