import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/x-date-pickers'
import { FilterList } from '@mui/icons-material'
import { TSetDataFilter } from '../blocks/types/types'
import { TListFilters } from './types/types'

const ListFilters = ({ dataFilters, setDataFilters }: TListFilters) => {
  const { t } = useTranslation(['home'])
  const [open, setOpen] = useState<boolean>(false)
  const [auxDataFilters, setAuxDataFilters] = useState<TSetDataFilter>({
    ...dataFilters,
    page: 0,
  })

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    status: boolean
  ) => {
    setAuxDataFilters({
      ...auxDataFilters,
      [event.target.name]: status,
    })
  }

  const handleDateChange = (value: any, name: string) => {
    setAuxDataFilters({
      ...auxDataFilters,
      [name]: value,
    })
  }

  const handleApplyFiltersAndClose = () => {
    setDataFilters(auxDataFilters)
    setOpen(false)
  }

  const handleResetFilters = () => {
    setAuxDataFilters({
      type: 'listFilter',
      paid: true,
      notPaid: true,
      fromDate: null,
      toDate: null,
      page: 0,
    })
  }

  return (
    <React.Fragment>
      <Box display={'flex'}>
        <Button
          sx={{ ml: 'auto', mb: 1 }}
          onClick={() => setOpen(true)}
          variant='outlined'
        >
          <FilterList />
          &nbsp;
          {t('default.filters')}
        </Button>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('default.filters')}</DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              <em>{t('default.status')}</em>
            </Typography>
            <FormGroup sx={{ display: 'block' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='paid'
                    checked={auxDataFilters.paid && true}
                    size='small'
                    onChange={(e) =>
                      handleStatusChange(e, !auxDataFilters.paid)
                    }
                  />
                }
                label={t('default.paid')}
              />
              <FormControlLabel
                sx={{ float: 'right' }}
                control={
                  <Checkbox
                    name='notPaid'
                    checked={auxDataFilters.notPaid && true}
                    size='small'
                    onChange={(e) =>
                      handleStatusChange(e, !auxDataFilters.notPaid)
                    }
                  />
                }
                label={t('default.not') + ' ' + t('default.paid')}
              />
            </FormGroup>
          </Box>
          <Box mt={2}>
            <Typography mb={1}>
              <em>{t('default.dateFilter')}</em>
            </Typography>
            <Box mb={1}>
              <DatePicker
                label={t('default.fromDate')}
                slotProps={{ textField: { size: 'small' } }}
                format='YYYY-MM-DD'
                value={auxDataFilters.fromDate}
                onChange={(newValue) => handleDateChange(newValue, 'fromDate')}
              />
            </Box>
            <Box>
              <DatePicker
                label={t('default.toDate')}
                slotProps={{ textField: { size: 'small' } }}
                format='YYYY-MM-DD'
                value={auxDataFilters.toDate}
                onChange={(newValue) => handleDateChange(newValue, 'toDate')}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            size='small'
            color='error'
            onClick={handleResetFilters}
          >
            Reset
          </Button>
          <Button
            variant='contained'
            size='small'
            color='success'
            onClick={handleApplyFiltersAndClose}
          >
            {t('default.apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default ListFilters
