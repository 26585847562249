import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import FinancialYearReport from '../../../components/admin/reports/FinancialYearReport'

const REPORT_TYPES = [
  {
    id: 1,
    label: 'Relatório fiscal anual',
  },
]

const ReportsMain = () => {
  const [selectedReport, setSelectedReport] = useState<number>(0)

  return (
    <Grid container xs={12} gap={2}>
      <Grid item xs={12}>
        <Typography variant='h2' className='content-title'>
          Gerar relatórios
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <FormControl fullWidth>
          <InputLabel id='tipo-de-relatorio' size='small'>
            Selecionar o tipo de relatório
          </InputLabel>
          <Select
            labelId='tipo-de-relatorio'
            label={'Selecionar o tipo de relatório'}
            onChange={(event) =>
              setSelectedReport(event.target.value as number)
            }
            value={selectedReport}
            size='small'
            fullWidth
          >
            <MenuItem disabled value='0'>
              <em>Selecione o tipo de relatório para ser gerado</em>
            </MenuItem>
            {REPORT_TYPES.map((report) => (
              <MenuItem key={report.id} value={report.id}>
                {report.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {selectedReport === 1 && <FinancialYearReport />}
    </Grid>
  )
}

export default ReportsMain
