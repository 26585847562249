import React from 'react'

export default function AdminHome() {
  return (
    <div>
      <h1>Pagina principal</h1>
      <br />
      <h2>Apenas será implementado na segunda fase do projecto</h2>
    </div>
  )
}
