import { enqueueSnackbar } from 'notistack'
import { useCallback } from 'react'

const useToastNotification = () => {
  const showSystemMessage = useCallback(
    (
      type: 'default' | 'error' | 'success' | 'warning' | 'info',
      message: string,
      duration?: number | 2000,
      persist?: boolean | false
    ) => {
      enqueueSnackbar(message, {
        variant: type,
        autoHideDuration: duration,
        persist: persist,
        style: { whiteSpace: 'pre-line' },
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      })
    },
    []
  )

  const showApiErrorMessage = useCallback(
    (error: any, defaultMessage: string) => {
      let messageToShow = defaultMessage
      if (error.response && error.response.data) {
        if (error.response.data.detail) {
          messageToShow = error.response.data.detail
        }

        if (error.response.data.message) {
          messageToShow = error.response.data.message
        }

        if (error.response.data['hydra:description']) {
          messageToShow = error.response.data['hydra:description']
        }
      }

      showSystemMessage('error', messageToShow)
    },
    [showSystemMessage]
  )

  return { showSystemMessage, showApiErrorMessage }
}

export default useToastNotification
