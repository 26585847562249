import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material'
import image from '../../images/predio3.png'
import { loginUserApi } from '../../utils/rest'
import { AuthContext } from '../../context/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import useToastNotification from '../../hooks/notifications/useToastNotification'
import { axiosBase } from '../../api/axios'
import { API_URLS } from '../../utils/helper/helper'
import moment from 'moment'

const Login = () => {
  const { setAuth, auth } = useContext(AuthContext)
  const navigate = useNavigate()
  const { showApiErrorMessage } = useToastNotification()
  const { t } = useTranslation(['home'])
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleLogIn = async () => {
    const loginResponse = await loginUserApi(email, password)

    if (!loginResponse) {
      showApiErrorMessage(t('errors.getDataError'), 'error')
      return
    }

    setAuth(loginResponse)

    navigate('/admin')
  }

  const getData = async () => {
    //Change this to custom hook
    const res = await axiosBase.get('https://api.ipify.org/?format=json')
    await axiosBase.post('/access-log', { ip: res.data.ip })
  }

  useEffect(() => {
    if (auth) {
      navigate(API_URLS.homepage)
    } else {
      getData()
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Box sx={{ margin: '15px 15px 0 15px', height: '100%' }}>
      <Grid
        container
        spacing={2}
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ height: '100%' }}
      >
        <Grid item xs={6} sx={{ height: '100%', padding: 0 }}>
          <Box
            sx={{
              height: '99%',
              backgroundColor: 'lightgray',
              borderRadius: '1rem',
            }}
          >
            <img
              style={{ height: '100%', width: '110%', objectFit: 'contain' }}
              src={image}
              alt=''
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ maxWidth: '50%', margin: '0 auto', minHeight: '470px' }}>
            <Box sx={{ padding: '24px', textAlign: 'center' }}>
              <Typography
                variant='h4'
                sx={{
                  fontWeight: 700,
                  fontSize: '1.5rem',
                  lineHeight: '1.375',
                }}
              >
                {t('login.signIn')}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontSize: '1rem',
                  fontWeight: 300,
                  lineHeight: '1.6',
                  color: '#7b809a',
                }}
              >
                {t('login.infoText')}
              </Typography>
            </Box>
            <Box sx={{ padding: '24px' }}>
              <TextField
                id='email'
                label='Email'
                onChange={(e) => setEmail(e.target.value)}
                size='small'
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <FormControl variant='outlined' size='small' fullWidth>
                <InputLabel htmlFor='password'>Password</InputLabel>
                <OutlinedInput
                  id='password'
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ marginBottom: 2 }}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Button variant='contained' fullWidth onClick={handleLogIn}>
                {t('login.button')}
              </Button>

              <Box>
                <Typography
                  component='h1'
                  variant='h5'
                  marginTop={5}
                  sx={{ color: 'grey', fontSize: '16px', fontWeight: 'bold' }}
                >
                  Informação importante
                </Typography>
                <Typography
                  marginTop={1}
                  sx={{ color: 'grey', fontSize: '14px' }}
                >
                  Este é um sistema privado. Apenas uso autorizado.
                </Typography>
                <Typography
                  marginTop={1}
                  sx={{ color: 'grey', fontSize: '14px' }}
                >
                  Todas as atividades são registadas e monitorizadas para
                  efeitos de manutenção da segurança e integridade do sistema.
                </Typography>
                <Typography
                  marginTop={1}
                  sx={{ color: 'grey', fontSize: '10px' }}
                >
                  &copy; Copyright {moment().year()} hlsystems.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Login
