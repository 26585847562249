import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/AuthProvider'

interface IRequiredRoles {
  requiredRoles: string[]
}

const RequireAuth = ({ requiredRoles }: IRequiredRoles) => {
  const { auth } = useContext(AuthContext)
  const location = useLocation()

  return auth?.roles.find((role) => requiredRoles?.includes(role)) ? (
    <Outlet />
  ) : auth?.name ? (
    <Navigate to='/unauthorized' state={{ from: location }} replace />
  ) : (
    <Navigate to='/' state={{ from: location }} replace />
  )
}

export default RequireAuth
