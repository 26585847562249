import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import React, { useState } from 'react'
import useReportFinancialYear from '../../../hooks/reports/useReportFinancialYear'
import { numberFormatting } from '../../../utils/helper/helper'
import LoadingCircular from '../../defaults/Loading'

const FinancialYearReport = () => {
  const [financialYear, setFinancialYear] = useState<moment.Moment>(moment())
  const [showReportPreview, setShowReportPreview] = useState<boolean>(false)
  const {
    handleFetchFinancialYearReport,
    financialYearData,
    isLoading,
    handleDownloadFinancialYearReport,
  } = useReportFinancialYear()

  return (
    <React.Fragment>
      <Grid item xs={7}>
        <DatePicker
          label='Ano Fiscal'
          views={['year']}
          value={financialYear}
          onChange={(newValue) =>
            setFinancialYear(newValue ? moment(newValue) : moment())
          }
          slotProps={{
            textField: {
              name: 'Ano Fiscal',
              size: 'small',
              fullWidth: true,
              error: false,
              variant: 'outlined',
              required: true,
            },
          }}
        />
      </Grid>

      <Grid item container xs={12} gap={2}>
        <Grid item xs={3.4}>
          <Button
            variant='contained'
            fullWidth
            disabled={isLoading}
            onClick={() => {
              handleFetchFinancialYearReport(financialYear.format('YYYY'))
              setShowReportPreview(true)
            }}
          >
            Pre Visualização
          </Button>
        </Grid>

        <Grid item xs={3.45}>
          <Button
            variant='contained'
            color='success'
            fullWidth
            disabled={isLoading}
            onClick={() => {
              handleDownloadFinancialYearReport(financialYear.format('YYYY'))
            }}
          >
            Gerar PDF
          </Button>
        </Grid>
      </Grid>

      {showReportPreview && isLoading && <LoadingCircular />}

      {showReportPreview && !isLoading && financialYearData && (
        <React.Fragment>
          <Grid item xs={5.5}>
            <TableContainer component={Paper}>
              <Typography
                variant='h4'
                textAlign={'center'}
                sx={{ bgcolor: 'lightcoral' }}
              >
                Despesas
              </Typography>
              <Table aria-label='simple table'>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {financialYearData.expenses.administration.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.expenses.administration.total
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.expenses.insurance.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.expenses.insurance.total
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.expenses.lift.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(financialYearData.expenses.lift.total)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.expenses.light.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(financialYearData.expenses.light.total)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.expenses.cleaning.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.expenses.cleaning.total
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.expenses.works.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(financialYearData.expenses.works.total)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.expenses.others.label}:
                      <br />
                      {financialYearData.expenses.others.items.map(
                        (item, idx) => (
                          <Box key={idx} sx={{ fontSize: 12, ml: 1 }}>
                            {item.label}
                            <br />
                          </Box>
                        )
                      )}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.expenses.others.total
                      )}
                      <br />
                      {financialYearData.expenses.others.items.map(
                        (item, idx) => (
                          <Box key={idx} sx={{ fontSize: 12, ml: 1 }}>
                            {numberFormatting(item.total)}
                            <br />
                          </Box>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow sx={{ backgroundColor: '#f4f4f5' }}>
                    <TableCell sx={{ fontWeight: 700, padding: '8px 16px' }}>
                      {financialYearData.expenses.totalExpenses.label}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700, padding: '8px 16px' }}>
                      {numberFormatting(
                        financialYearData.expenses.totalExpenses.total
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={5.5}>
            <TableContainer component={Paper}>
              <Typography
                variant='h4'
                textAlign={'center'}
                sx={{ bgcolor: 'lightgreen' }}
              >
                Receitas
              </Typography>
              <Table aria-label='simple table'>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {financialYearData.incomes.anualQuotes.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.incomes.anualQuotes.total
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.incomes.insurance.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.incomes.insurance.total
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.incomes.works.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(financialYearData.incomes.works.total)}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow sx={{ backgroundColor: '#f4f4f5' }}>
                    <TableCell sx={{ fontWeight: 700, padding: '8px 16px' }}>
                      {financialYearData.incomes.totalIncomes.label}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700, padding: '8px 16px' }}>
                      {numberFormatting(
                        financialYearData.incomes.totalIncomes.total
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Typography
                variant='h4'
                textAlign={'center'}
                sx={{ bgcolor: 'lightblue' }}
              >
                Resumo
              </Typography>
              <Table aria-label='simple table'>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {financialYearData.balance.initialBalance.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.balance.initialBalance.total
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.balance.incomes.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.balance.incomes.total
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.balance.expenses.label}
                    </TableCell>
                    <TableCell>
                      {numberFormatting(
                        financialYearData.balance.expenses.total
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {financialYearData.balance.yearBalance.label}
                    </TableCell>
                    <TableCell
                      sx={{
                        color:
                          financialYearData.balance.yearBalance.total >= 0
                            ? 'green'
                            : 'red',
                      }}
                    >
                      {numberFormatting(
                        financialYearData.balance.yearBalance.total
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow sx={{ backgroundColor: '#f4f4f5' }}>
                    <TableCell sx={{ fontWeight: 700, padding: '8px 16px' }}>
                      {financialYearData.balance.finalBalance.label}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 700,
                        padding: '8px 16px',
                      }}
                    >
                      {numberFormatting(
                        financialYearData.balance.finalBalance.total
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default FinancialYearReport
