import React, { useState } from 'react'
import { TResidentInfo } from '../types/types'
import { AccountCircle, NoAccounts } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import ResidentUserAccountPopup from './ResidentUserAccountPopup'
import { useTranslation } from 'react-i18next'

const ResidentOnlineAccount = ({
  residentData,
  setRefreshData,
  refreshData,
}: TResidentInfo) => {
  const { t } = useTranslation(['home'])
  const [openUserPopup, setOpenUserPopup] = useState(false)

  return (
    <React.Fragment>
      {!residentData.user ? (
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
          <NoAccounts color='error' sx={{ mr: 1 }} />
          {t('admin.management.residents.edit.noOnlineAccount')}
          <Button
            variant='contained'
            size='small'
            color='warning'
            sx={{ ml: 'auto', mr: 1 }}
            onClick={() => setOpenUserPopup(true)}
          >
            {t('admin.management.residents.edit.createAccount')}
          </Button>
        </Box>
      ) : residentData.user.isActive ? (
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
          <AccountCircle color='success' sx={{ mr: 1 }} />
          {t('admin.management.residents.edit.activeOnlineAccount')}
          <Button
            variant='contained'
            size='small'
            color='primary'
            sx={{ ml: 'auto', mr: 1 }}
            onClick={() => setOpenUserPopup(true)}
          >
            {t('default.viewDetails')}
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
          <NoAccounts color='warning' sx={{ mr: 1 }} />
          {t('admin.management.residents.edit.inactiveOnlineAccount')}
          <Button
            variant='contained'
            size='small'
            color='primary'
            sx={{ ml: 'auto', mr: 1 }}
            onClick={() => setOpenUserPopup(true)}
          >
            {t('default.viewDetails')}
          </Button>
        </Box>
      )}

      <ResidentUserAccountPopup
        userData={residentData.user}
        openUserPopup={openUserPopup}
        setOpenUserPopup={setOpenUserPopup}
        residentId={residentData['@id']}
        setRefreshData={setRefreshData}
        refreshData={refreshData}
      />
    </React.Fragment>
  )
}

export default ResidentOnlineAccount
